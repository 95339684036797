import type { FocusEvent, MouseEvent } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { DateFormatter, getLocalTimeZone, today } from '@internationalized/date';
import { useLocale } from '@react-aria/i18n';

import type { DateRange, DateRangeDropdownItem, DateRangeDropdownItems, GetDropdownItems } from './types';
import { DateRangePickerValue } from './types';

export const formatDateRange = (
  v: DateRange,
  dateFormatter: (date: CalendarDate, timeZone?: string) => string,
  timeZone?: string,
) =>
  [v.startDate, v.endDate]
    .map((date) => dateFormatter(date, timeZone))
    .filter((value, index, array) => value !== array[index - 1]) // remove duplication
    .join(' – ');

export const convertDateRangePickerValueToDateRange = (
  value: Exclude<DateRangePickerValue, DateRangePickerValue.Custom>,
  timeZone: string = getLocalTimeZone(),
): DateRange | undefined => {
  const endDate = today(timeZone);
  const startDate = today(timeZone);
  switch (value) {
    case DateRangePickerValue.Today:
      return { startDate: endDate, endDate };
    case DateRangePickerValue.Yesterday: {
      const yesterday = endDate.subtract({ days: 1 });
      return {
        startDate: yesterday,
        endDate: yesterday,
      };
    }
    case DateRangePickerValue.Last7Days:
      return {
        startDate: startDate.subtract({ days: 6 }),
        endDate,
      };
    case DateRangePickerValue.Last14Days:
      return {
        startDate: startDate.subtract({ days: 13 }),
        endDate,
      };
    case DateRangePickerValue.Last30Days:
      return {
        startDate: startDate.subtract({ days: 29 }),
        endDate,
      };
    case DateRangePickerValue.Last90Days:
      return {
        startDate: startDate.subtract({ days: 89 }),
        endDate,
      };
    case DateRangePickerValue.Last180Days:
      return {
        startDate: startDate.subtract({ days: 179 }),
        endDate,
      };
    default:
      return undefined;
  }
};

export const getDefaultMenuProps = (closeMenu: () => void) => {
  return {
    onMouseUp: (event) => {
      // prevent document mouseup event handler (which will close the date picker) from being called.
      event.stopPropagation();
    },
    onKeyUp: (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        (document.activeElement as HTMLElement)?.blur();
        closeMenu();
      }
    },
  };
};

const isFirefoxOrSafari = () => {
  try {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || navigator.userAgent.search(/firefox/i);
  } catch (e) {
    return false;
  }
};

/**
 * Event handlers to open a calendar when the picker is focused.
 *
 * Basically, we can call `toggleMenu()` inside focus event handler for this behavior.
 *
 * However in most browsers, focus event will be dispatched when a user clicks the picker, which will make
 * the calendar opened by Downshift on click closed by `toggleMenu()` in focus event handler.
 * Thus, we need to stop the default onclick behavior of Downshift.
 *
 * In addition, when the user clicks a focused picker, focus event won't be dispatched because it was
 * already focused. We need to handle that case manually in mousedown event handler to close the calendar.
 *
 * On the other hand on Safari, clicking a picker does not dispatch focus event. A focus event will be
 * dispatched only when it is focused using tab key. So we preserve the default onclick behavior of
 * Downshift on Safari, just adding the focus event handler.
 */

const getConditionalEvents = (toggleMenu: () => void) => {
  if (isFirefoxOrSafari()) {
    return {
      onMouseDown: (event: MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.style.boxShadow = '';
      },
    };
  }
  return {
    onMouseDown: (event: MouseEvent<HTMLButtonElement>) => {
      event.currentTarget.style.boxShadow = '';
      if (document.activeElement === event.currentTarget) {
        // if the target is focused, onFocus won't be called. so we have to call toggleMenu here.
        toggleMenu();
      }
      // otherwise onFocus will be called calling toggleMenu inside it.
    },
    onClick: (event: MouseEvent<HTMLButtonElement>) => {
      event.nativeEvent['preventDownshiftDefault'] = true;
    },
  };
};

export const getDefaultToggleButtonProps = (toggleMenu: () => void) => ({
  onFocus: (event: FocusEvent<HTMLButtonElement>) => {
    event.currentTarget.style.boxShadow = '';
    toggleMenu();
  },
  onMouseUp: (event: MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.style.boxShadow = 'none';
  },
  ...getConditionalEvents(toggleMenu),
});

export const getSelectedDropdownItems = ({
  selectedItems,
  dropdownItems,
}: {
  selectedItems: DateRangePickerValue[];
  dropdownItems: DateRangeDropdownItems;
}) => {
  const predefinedDateRangeItems: DateRangeDropdownItem[] = [
    dropdownItems.today,
    dropdownItems.yesterday,
    dropdownItems.last7Days,
    dropdownItems.last14Days,
    dropdownItems.last30Days,
    dropdownItems.last90Days,
    dropdownItems.last180Days!,
  ];
  return predefinedDateRangeItems.filter((item) => selectedItems.includes(item.value));
};

export const getDropdownItems: GetDropdownItems = ({
  dropdownItems,
  hasAllDays,
  hasLast180Days,
  maximumNights,
  minimumNights,
  hideCustomDateRange,
}) => {
  const predefinedDateRangeItems: DateRangeDropdownItem[] = [
    dropdownItems.today,
    dropdownItems.yesterday,
    dropdownItems.last7Days,
    dropdownItems.last14Days,
    dropdownItems.last30Days,
    dropdownItems.last90Days,
  ].concat(hasLast180Days ? [dropdownItems.last180Days!] : []);

  if (maximumNights) {
    if (hasLast180Days && maximumNights >= 179) predefinedDateRangeItems.splice(7);
    if (maximumNights >= 89) predefinedDateRangeItems.splice(6);
    else if (maximumNights >= 29) predefinedDateRangeItems.splice(5);
    else if (maximumNights >= 13) predefinedDateRangeItems.splice(4);
    else if (maximumNights >= 6) predefinedDateRangeItems.splice(3);
    else if (maximumNights >= 2) predefinedDateRangeItems.splice(2);
    else if (maximumNights === 1) predefinedDateRangeItems.splice(1);
  } else if (hasAllDays) {
    predefinedDateRangeItems.push(dropdownItems.allDates!);
  }

  if (minimumNights) {
    if (hasLast180Days && minimumNights > 180) predefinedDateRangeItems.splice(0, 7);
    if (minimumNights > 90) predefinedDateRangeItems.splice(0, 6);
    else if (minimumNights > 30) predefinedDateRangeItems.splice(0, 5);
    else if (minimumNights > 14) predefinedDateRangeItems.splice(0, 4);
    else if (minimumNights > 7) predefinedDateRangeItems.splice(0, 3);
    else if (minimumNights > 1) predefinedDateRangeItems.splice(0, 2);
  }

  return hideCustomDateRange
    ? [{ items: predefinedDateRangeItems }]
    : [{ items: predefinedDateRangeItems }, { items: [dropdownItems.custom] }];
};

export const useDefaultDateFormatter = () => {
  const locale = useLocale();

  return (date: CalendarDate, timeZone: string = getLocalTimeZone()) => {
    const dateFormatter = new DateFormatter(locale.locale, { dateStyle: 'long', timeZone });
    return dateFormatter.format(date.toDate(timeZone));
  };
};
