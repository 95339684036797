import { useEffect, useRef } from 'react';

import type { ToastOption } from '@feather';
import { toast } from '@feather/components/notification';
import getErrorMessage from '@utils/getErrorMessage';

const useErrorToast = (
  error: any,
  options?: { ignoreDuplicates?: boolean; type?: 'warning' } & Partial<ToastOption>,
) => {
  const { ignoreDuplicates = false, ...toastOptions } = options || {};
  const toastOptionRef = useRef(toastOptions); // prevent calling `useEffect` multiple times
  const errorMessage = error && getErrorMessage(error);

  useEffect(() => {
    if (!ignoreDuplicates) {
      return;
    }
    if (errorMessage) {
      if (toastOptionRef.current.type === 'warning') {
        toast.warning({ message: errorMessage, ...toastOptionRef.current });
        return;
      }
      toast.error({ message: errorMessage, ...toastOptionRef.current });
    }
  }, [errorMessage, ignoreDuplicates]);

  useEffect(() => {
    if (ignoreDuplicates) {
      return;
    }
    if (error) {
      if (toastOptionRef.current.type === 'warning') {
        toast.warning({ message: getErrorMessage(error), ...toastOptionRef.current });
        return;
      }
      toast.error({ message: getErrorMessage(error), ...toastOptionRef.current });
    }
  }, [error, ignoreDuplicates]);
};

export default useErrorToast;
