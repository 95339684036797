import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const EmojiBadSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      <path
        d="M2.083 20c0-9.895 8.022-17.917 17.917-17.917 9.895 0 17.917 8.022 17.917 17.917 0 9.895-8.022 17.917-17.917 17.917-9.895 0-17.917-8.022-17.917-17.917Z"
        fill="#FFD46E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 27.334A9.546 9.546 0 0 1 20 25.417c2.156 0 4.15.713 5.75 1.917a1.25 1.25 0 1 1-1.5 1.998A7.045 7.045 0 0 0 20 27.918a7.045 7.045 0 0 0-4.25 1.415 1.25 1.25 0 1 1-1.5-1.998Z"
        fill="#141B34"
      />
      <path
        d="M23.445 14.596a1.25 1.25 0 0 1-.224-2.49c.082-.005.342-.02.481-.022.277-.004.662.003 1.097.048.824.084 2.032.323 2.972 1.07.651.517 1.145 1.148 1.488 1.668.466.706.405 1.54-.022 2.151-.402.576-1.074.896-1.776.896-1.304 0-2.574-1.214-2.098-2.722l.124-.393a4.942 4.942 0 0 0-.943-.183 6.969 6.969 0 0 0-1.099-.023ZM11.778 14.596a1.25 1.25 0 0 1-.224-2.49c.082-.005.342-.02.481-.022.277-.004.662.003 1.098.048.823.084 2.031.323 2.97 1.07.652.517 1.146 1.148 1.49 1.668.465.706.404 1.54-.023 2.151-.402.576-1.074.896-1.776.896-1.304 0-2.574-1.214-2.098-2.722l.124-.393a4.938 4.938 0 0 0-.943-.183 6.968 6.968 0 0 0-1.099-.023Z"
        fill="#141B34"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const EmojiBad = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={EmojiBadSvg} {...props} />
));
EmojiBad.displayName = 'EmojiBad';
export default EmojiBad;
