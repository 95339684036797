import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DivideSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 4C19.3284 4 20 4.67157 20 5.5C20 6.32843 19.3284 7 18.5 7C17.6716 7 17 6.32843 17 5.5C17 4.67157 17.6716 4 18.5 4ZM18.5 2C16.9145 2 15.5752 3.05426 15.1449 4.5H10.5V11H8.91946C8.57612 9.42741 7.17556 8.25 5.5 8.25C3.567 8.25 2 9.817 2 11.75C2 13.683 3.567 15.25 5.5 15.25C6.9926 15.25 8.26697 14.3157 8.77019 13H10.5V19.5H15.1449C15.5752 20.9457 16.9145 22 18.5 22C20.433 22 22 20.433 22 18.5C22 16.567 20.433 15 18.5 15C16.9145 15 15.5752 16.0543 15.1449 17.5H12.5V13V11V6.5H15.1449C15.5752 7.94574 16.9145 9 18.5 9C20.433 9 22 7.433 22 5.5C22 3.567 20.433 2 18.5 2ZM17 18.5C17 17.6716 17.6716 17 18.5 17C19.3284 17 20 17.6716 20 18.5C20 19.3284 19.3284 20 18.5 20C17.6716 20 17 19.3284 17 18.5ZM5.5 10.25C6.32843 10.25 7 10.9216 7 11.75C7 12.5784 6.32843 13.25 5.5 13.25C4.67157 13.25 4 12.5784 4 11.75C4 10.9216 4.67157 10.25 5.5 10.25Z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Divide = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DivideSvg} {...props} />
));
Divide.displayName = 'Divide';
export default Divide;
