import type { MouseEventHandler, ReactNode } from 'react';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { getLocalTimeZone } from '@internationalized/date';
import type { RangeValue } from '@react-types/shared';
import type { PopperChildrenProps } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';
import styled, { css } from 'styled-components';

import { Button } from '@feather/components/button';
import { Dropdown, DropdownToggleIcon } from '@feather/components/dropdown';
import { toast } from '@feather/components/notification';
import { TooltipVariant } from '@feather/components/tooltip';
import { elevation } from '@feather/elevation';
import { cssVariables } from '@feather/theme';
import { ZIndexes } from '@feather/zIndexes';

import { ToggleText } from './ToggleText';
import RangeCalendar from './components/RangeCalendar';
import type {
  DatePickerCommonProps,
  DatePickerDropdownProps,
  DateRange,
  DateRangeDropdownItem,
  DateRangeDropdownItems,
  DateRangeItemLabel,
} from './types';
import { DateRangePickerValue } from './types';
import {
  convertDateRangePickerValueToDateRange,
  formatDateRange,
  getDropdownItems,
  getSelectedDropdownItems,
  useDefaultDateFormatter,
} from './utils';

const hideDropdownClickInterceptor = (node: HTMLElement | null) => {
  if (node) {
    node.style.width = '0';
    node.style.height = '0';
  }
};

const DateRangePickerWrapper = styled.div<{ fullWidth?: boolean }>`
  position: relative;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

const DatePickerDropdownProxy = forwardRef<HTMLButtonElement, DatePickerDropdownProps>((props, ref) =>
  Dropdown<DateRangeDropdownItem>({ ...props, toggleButtonRef: ref }),
);

const DatePickerDropdown = styled(DatePickerDropdownProxy)<{ isDayPickerVisible: boolean }>`
  ${(props) =>
    props.isDayPickerVisible &&
    // If the calendar is visible, dropdown must be look like it's active.
    css`
      color: ${cssVariables('purple-7')} !important;
      border-color: ${cssVariables('purple-7')} !important;
      background-color: ${cssVariables('purple-2')} !important;

      ${DropdownToggleIcon} {
        fill: ${cssVariables('purple-7')} !important;
      }

      /* Arrow icon */
      ${DropdownToggleIcon} {
        transform: rotate(180deg);
      }
    `}
`;

const DropdownClickEventInterceptor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const CalendarWrapper = styled.div<{ width?: number | string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  border-radius: 4px;
  overflow: hidden;
  ${elevation.popover}
  z-index: ${ZIndexes.dropdownMenu};
  background: #ffffff;
  ${({ width }) =>
    width &&
    css`
      width: ${typeof width === 'number' ? `${width}px` : width};
    `}
  padding: 12px 16px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: end;
  margin: 16px -16px -12px -16px;
  padding: 12px 16px;
  box-shadow: inset ${cssVariables('neutral-3')} 0 1px;
  z-index: 1; /* <- should guarantee inset-shadow not be hidden by the calendar */
`;

export type DateRangePickerProps = Omit<DatePickerCommonProps, 'enableOutsideDays'> & {
  cancelText?: string;
  confirmText?: string;
  dateRange?: DateRange;
  dayPickerProps?: Pick<DatePickerDropdownProps, 'positionFixed' | 'modifiers'>;
  dropdownProps?: Omit<
    DatePickerDropdownProps,
    'placement' | 'selectedItem' | 'itemType' | 'items' | 'onChange' | 'tooltipProps'
  > & { tooltipProps?: Omit<NonNullable<DatePickerDropdownProps['tooltipProps']>, 'content'> }; // Content must be determined by DateRangePicker component itself.
  customDropdownItems?: DateRangePickerValue[];
  fullWidth?: boolean;
  itemLabel?: DateRangeItemLabel;
  maximumNights?: number;
  minimumNights?: number;
  onChange: (value: DateRangePickerValue, dateRange?: DateRange) => void;
  value: DateRangePickerValue;
  selectedItemPrefix?: ReactNode;
  hideCustomDateRange?: boolean;
};
const DateRangePicker = ({
  cancelText = 'Cancel',
  confirmText = 'Apply',
  dateRange,
  dayPickerProps,
  disabled = false,
  dropdownProps,
  customDropdownItems,
  formatDate,
  fullWidth = false,
  itemLabel,
  maxDate,
  maximumNights = undefined,
  minDate,
  minimumNights = 0,
  onChange,
  placement = 'bottom-start',
  size = 'medium',
  timeZone = getLocalTimeZone(),
  value,
  selectedItemPrefix,
  hideCustomDateRange = false,
}: DateRangePickerProps) => {
  const [dayPickerRange, setDayPickerRange] = useState<
    { startDate: CalendarDate | null; endDate: CalendarDate | null } | undefined
  >();
  const [isCalendarShown, setIsCalendarShown] = useState(false);
  const dropdownRef = useRef<HTMLButtonElement>(null);
  const dropdownClickEventInterceptorRef = useRef<HTMLDivElement>(null);
  const scheduleUpdateRef = useRef<PopperChildrenProps['scheduleUpdate']>();
  const defaultDateFormatter = useDefaultDateFormatter();

  const dateRangeDropdownItems: DateRangeDropdownItems = {
    today: {
      value: DateRangePickerValue.Today,
      label: itemLabel?.today ?? DateRangePickerValue.Today,
    },
    yesterday: {
      value: DateRangePickerValue.Yesterday,
      label: itemLabel?.yesterday ?? DateRangePickerValue.Yesterday,
    },
    last7Days: {
      value: DateRangePickerValue.Last7Days,
      label: itemLabel?.last7Days ?? DateRangePickerValue.Last7Days,
    },
    last14Days: {
      value: DateRangePickerValue.Last14Days,
      label: itemLabel?.last14Days ?? DateRangePickerValue.Last14Days,
    },
    last30Days: {
      value: DateRangePickerValue.Last30Days,
      label: itemLabel?.last30Days ?? DateRangePickerValue.Last30Days,
    },
    last90Days: {
      value: DateRangePickerValue.Last90Days,
      label: itemLabel?.last90Days ?? DateRangePickerValue.Last90Days,
    },
    allDates: {
      value: DateRangePickerValue.AllDates,
      label: itemLabel?.allDates ?? DateRangePickerValue.AllDates,
    },
    custom: {
      value: DateRangePickerValue.Custom,
      label: itemLabel?.custom ?? DateRangePickerValue.Custom,
    },
  };

  const isDayPickerVisible = !!(isCalendarShown && dayPickerRange);
  const selectedItem = isDayPickerVisible
    ? dateRangeDropdownItems.custom
    : Object.values(dateRangeDropdownItems).find((item) => item.value === value);

  const getToggleText = (item?: DateRangeDropdownItem | null) => {
    if (!item) return '';
    if (item.value === DateRangePickerValue.Custom) {
      const toggleDisplayedDateRange: DateRange | undefined =
        dayPickerRange && dayPickerRange.startDate && dayPickerRange.endDate
          ? (dayPickerRange as DateRange)
          : dateRange;

      if (toggleDisplayedDateRange) {
        return formatDateRange(toggleDisplayedDateRange, formatDate ?? defaultDateFormatter, timeZone);
      }
    }
    return item.label;
  };

  const toggleText = getToggleText(selectedItem);

  useEffect(() => {
    if (!!dateRange && selectedItem?.value === DateRangePickerValue.Custom) {
      setDayPickerRange(dateRange);
    }
  }, [dateRange, selectedItem?.value]);

  useEffect(() => {
    if (!isDayPickerVisible) {
      hideDropdownClickInterceptor(dropdownClickEventInterceptorRef.current);
      return;
    }

    if (dropdownClickEventInterceptorRef.current && dropdownRef.current) {
      dropdownClickEventInterceptorRef.current.style.zIndex = String(
        getComputedStyle(dropdownClickEventInterceptorRef.current).zIndex + 1,
      );
      dropdownClickEventInterceptorRef.current.style.width = `${dropdownRef.current.clientWidth}px`;
      dropdownClickEventInterceptorRef.current.style.height = `${dropdownRef.current.clientHeight}px`;

      scheduleUpdateRef.current?.();
    }
  }, [toggleText, size, isDayPickerVisible]);

  const toggleRenderer: DatePickerDropdownProps['toggleRenderer'] = ({ selectedItemPrefix, selectedItem }) => (
    <ToggleText size={size}>
      {selectedItemPrefix}
      {getToggleText(selectedItem)}
    </ToggleText>
  );

  const notifyChange = (value: DateRangePickerValue, customDateRange?: DateRange) => {
    if (value === DateRangePickerValue.Custom && customDateRange == null) {
      return;
    }

    const dateRange =
      value === DateRangePickerValue.Custom ? customDateRange : convertDateRangePickerValueToDateRange(value, timeZone);
    onChange?.(value, dateRange);
  };

  const onItemSelected: DatePickerDropdownProps['onItemSelected'] = (item) => {
    if (item == null) {
      return;
    }
    if (onChange == null) {
      return;
    }

    if (item.value === DateRangePickerValue.Custom) {
      // Don't call onChange until a custom date range is set.
      setDayPickerRange(dateRange || { startDate: null, endDate: null });
      setIsCalendarShown(true);
      return;
    }

    notifyChange(item.value);
  };

  const onDropdownClickEventInterceptorClick: MouseEventHandler<HTMLDivElement> = () => {
    hideDropdownClickInterceptor(dropdownClickEventInterceptorRef.current);
    setDayPickerRange(undefined);
  };

  const handleChange = ({ start: startDate, end: endDate }: RangeValue<CalendarDate>) => {
    setDayPickerRange({ startDate, endDate });
  };

  const onDayPickerCancelButtonClick = () => {
    hideDropdownClickInterceptor(dropdownClickEventInterceptorRef.current);
    setDayPickerRange(undefined);
  };

  const onDayPickerApplyButtonClick = () => {
    if (dayPickerRange == null) {
      return;
    }
    const { startDate, endDate } = dayPickerRange;
    if (startDate == null) {
      toast.error({ message: 'Select the start date.' });
      return;
    }
    if (endDate == null) {
      toast.error({ message: 'Select the end date.' });
      return;
    }

    notifyChange(DateRangePickerValue.Custom, { startDate, endDate });

    // Close the calendar.
    hideDropdownClickInterceptor(dropdownClickEventInterceptorRef.current);
    setIsCalendarShown(false);
  };

  const tooltipText = (() => {
    if (value === DateRangePickerValue.Custom || isDayPickerVisible) {
      return undefined;
    }

    const specificDateRange = convertDateRangePickerValueToDateRange(value, timeZone);
    return specificDateRange
      ? formatDateRange(specificDateRange, formatDate ?? defaultDateFormatter, timeZone)
      : undefined;
  })();

  const dropdownWidth = useMemo(() => {
    if (dropdownProps?.width) return dropdownProps.width;
    return fullWidth ? '100%' : 'auto';
  }, [fullWidth, dropdownProps?.width]);

  return (
    <DateRangePickerWrapper fullWidth={fullWidth}>
      <DatePickerDropdown
        {...dropdownProps}
        ref={dropdownRef}
        disabled={disabled}
        isDayPickerVisible={isDayPickerVisible}
        items={
          customDropdownItems
            ? getSelectedDropdownItems({
                selectedItems: customDropdownItems,
                dropdownItems: {
                  ...dateRangeDropdownItems,
                  last180Days: {
                    value: DateRangePickerValue.Last180Days,
                    label: itemLabel?.last180Days ?? DateRangePickerValue.Last180Days,
                  },
                },
              })
            : getDropdownItems({
                dropdownItems: dateRangeDropdownItems,
                hasAllDays: true,
                maximumNights,
                minimumNights,
                hideCustomDateRange,
              })
        }
        itemsType={customDropdownItems ? 'array' : 'section'}
        itemToString={(item) => item.label}
        onItemSelected={onItemSelected}
        placement={placement}
        selectedItem={selectedItem}
        size={size}
        selectedItemPrefix={selectedItemPrefix}
        toggleRenderer={toggleRenderer}
        tooltipProps={
          tooltipText
            ? {
                variant: TooltipVariant.Light,
                content: tooltipText,
                placement: 'top',
                ...dropdownProps?.tooltipProps,
              }
            : undefined
        }
        width={dropdownWidth}
      />
      <Manager>
        <Reference innerRef={dropdownClickEventInterceptorRef}>
          {({ ref }) => <DropdownClickEventInterceptor ref={ref} onClick={onDropdownClickEventInterceptorClick} />}
        </Reference>
        {isCalendarShown && dayPickerRange && (
          <Popper
            modifiers={dayPickerProps?.modifiers}
            placement={placement}
            positionFixed={dayPickerProps?.positionFixed}
          >
            {({ ref, style, scheduleUpdate }) => {
              scheduleUpdateRef.current = scheduleUpdate;
              return (
                <CalendarWrapper ref={ref} style={{ ...style, top: 2 }}>
                  <RangeCalendar
                    isDisabled={disabled}
                    maximumNights={maximumNights}
                    maxValue={maxDate ?? undefined}
                    minimumNights={minimumNights}
                    minValue={minDate ?? undefined}
                    onChange={handleChange}
                    timeZone={timeZone}
                    value={
                      dayPickerRange?.startDate && dayPickerRange.endDate
                        ? {
                            start: dayPickerRange.startDate,
                            end: dayPickerRange.endDate,
                          }
                        : undefined
                    }
                  />
                  <ButtonContainer>
                    <Button buttonType="tertiary" size="small" onClick={onDayPickerCancelButtonClick}>
                      {cancelText}
                    </Button>
                    <Button buttonType="primary" size="small" onClick={onDayPickerApplyButtonClick}>
                      {confirmText}
                    </Button>
                  </ButtonContainer>
                </CalendarWrapper>
              );
            }}
          </Popper>
        )}
      </Manager>
    </DateRangePickerWrapper>
  );
};

export default DateRangePicker;
