import { QueryCache, QueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { toast } from '@feather/components/notification';
import getErrorMessage from '@utils/getErrorMessage';
import logException from '@utils/logException';

const BLACKLISTED_ERROR_MESSAGES = ['Required permission validation failed'];

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (!query?.meta?.skipErrorToast) {
        if (query?.meta?.errorMessage) {
          toast.error({ message: query?.meta.errorMessage as string });
        } else {
          const message = getErrorMessage(error);
          if (BLACKLISTED_ERROR_MESSAGES.includes(message)) {
            return;
          }
          toast.error({ message });
        }
      }
      if (!axios.isAxiosError(error)) {
        logException(error);
      }
    },
  }),
});
