import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const EmojiOkaySvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      <path
        d="M2.083 20c0-9.895 8.022-17.917 17.917-17.917 9.895 0 17.917 8.022 17.917 17.917 0 9.895-8.022 17.917-17.917 17.917-9.895 0-17.917-8.022-17.917-17.917Z"
        fill="#FFD46E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 15c0-1.15.933-2.083 2.083-2.083h.015a2.083 2.083 0 1 1 0 4.166h-.015A2.083 2.083 0 0 1 11.25 15Zm13.318 0c0-1.15.933-2.083 2.084-2.083h.015a2.083 2.083 0 1 1 0 4.166h-.015A2.084 2.084 0 0 1 24.568 15ZM13.75 26.667c0-.69.56-1.25 1.25-1.25h10a1.25 1.25 0 0 1 0 2.5H15c-.69 0-1.25-.56-1.25-1.25Z"
        fill="#141B34"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const EmojiOkay = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={EmojiOkaySvg} {...props} />
));
EmojiOkay.displayName = 'EmojiOkay';
export default EmojiOkay;
