import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const AIAssistedSVG = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      <path
        d="M15.9221 6.6663L16.5805 5.20796L18.0388 4.54963C18.3638 4.39963 18.3638 3.9413 18.0388 3.7913L16.5805 3.13296L15.9221 1.6663C15.7721 1.3413 15.3138 1.3413 15.1638 1.6663L14.5055 3.12463L13.0388 3.78296C12.7138 3.93296 12.7138 4.3913 13.0388 4.5413L14.4971 5.19963L15.1555 6.6663C15.3055 6.9913 15.7721 6.9913 15.9221 6.6663ZM9.28879 7.9163L7.96379 4.99963C7.67212 4.34963 6.73879 4.34963 6.44712 4.99963L5.12212 7.9163L2.20546 9.2413C1.55546 9.5413 1.55546 10.4663 2.20546 10.758L5.12212 12.083L6.44712 14.9996C6.74712 15.6496 7.67212 15.6496 7.96379 14.9996L9.28879 12.083L12.2055 10.758C12.8555 10.458 12.8555 9.53296 12.2055 9.2413L9.28879 7.9163ZM15.1555 13.333L14.4971 14.7913L13.0388 15.4496C12.7138 15.5996 12.7138 16.058 13.0388 16.208L14.4971 16.8663L15.1555 18.333C15.3055 18.658 15.7638 18.658 15.9138 18.333L16.5721 16.8746L18.0388 16.2163C18.3638 16.0663 18.3638 15.608 18.0388 15.458L16.5805 14.7996L15.9221 13.333C15.7721 13.008 15.3055 13.008 15.1555 13.333Z"
        fill="url(#paint0_linear_359_12634)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_359_12634"
          x1="4.99921"
          y1="-1.99994"
          x2="22.2904"
          y2="3.83311"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8B35FF" />
          <stop offset="1" stopColor="#62D0FF" />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const AIAssisted = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={AIAssistedSVG} {...props} />
));
AIAssisted.displayName = 'AIAssisted';
export default AIAssisted;
