import { SessionStorage } from '@utils/ClientStorage';

/**
 * Use to determine if the user using Aero mode
 * @returns {setAeroMode: (mode: boolean) => void, isAeroMode: () => boolean}
 */
const useAeroMode = () => {
  return {
    setAeroMode: (mode: boolean) => {
      SessionStorage.set('isAeroMode', mode.toString());
      location.href = '/';
    },
    isAeroMode: SessionStorage.get('isAeroMode') === 'true',
  };
};

export default useAeroMode;
