import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { AccountActions } from '@common/redux/actions/account';
import { AuthenticationActions } from '@common/redux/actions/authentication';

export interface AuthenticationState {
  authenticated: boolean;
  user: AuthUser;
  role: MemberRole;
  isFetching: boolean;
  auto_pw?: boolean;
  is_social?: boolean;
  is_sso?: boolean;
}

const initialState: AuthenticationState = {
  isFetching: false,
  authenticated: false,
  user: {
    country_code: '',
    email: '',
    new_email: '',
    email_verified: false,
    nickname: '',
    first_name: '',
    last_name: '',
    country_name: '',
    profile_id: 0,
    profile_url: '',
    user_id: 0,
    two_factor_authentication: false,
    verification_email_sent: false,
    coachmark_completed: false,
    join_date: '',
    account_locked: false,
    last_password_changed_at: '',
    is_onboarding_survey_done: false,
  },
  role: {
    name: 'GUEST',
    permissions: [],
    is_predefined: false,
    description: '',
    is_application_access_control: false,
    application_access_controls: [],
    application_access_controls_details: [],
    created_at: '',
    updated_at: '',
    id: 0,
    pii_access: 'none',
  },
  is_social: false,
  is_sso: false,
};

export const authenticationReducer = createReducer<AuthenticationState>(initialState, (builder) => {
  // AuthenticationActions
  builder
    .addCase(AuthenticationActions.authFetchingSet, (state) => {
      state.isFetching = true;
    })
    .addCase(AuthenticationActions.authFetchingUnset, (state) => {
      state.isFetching = false;
    })
    .addCase(AuthenticationActions.verifyAuthentication.pending, (state) => {
      state.isFetching = true;
    })
    .addCase(AuthenticationActions.authenticated, (state, { payload }) => {
      return {
        ...state,
        isFetching: false,
        authenticated: true,
        ...payload,
      };
    })
    .addCase(AuthenticationActions.signedIn, (state, { payload }) => {
      return {
        ...state,
        authenticated: !payload.two_factor_authentication_required,
        isFetching: false,
        ...payload,
      };
    })
    .addCase(AuthenticationActions.verificationMailSent, (state) => {
      state.user.verification_email_sent = true;
    })
    .addCase(AuthenticationActions.emailVerified, (state) => {
      state.user.email_verified = true;
      state.isFetching = false;
    })
    .addCase(AuthenticationActions.accountActivated, (state) => {
      state.user.account_locked = false;
      state.isFetching = false;
    })
    .addCase(AuthenticationActions.twoFactorAuthenticationSet, (state, { payload }) => {
      state.user.two_factor_authentication = payload.two_factor_authentication;
    })
    .addCase(AuthenticationActions.unauthenticated, () => initialState);

  // Other actions
  builder
    .addCase(AccountActions.changeEmail.fulfilled, (state, { payload }) => {
      state.user.new_email = payload.newEmail;
    })
    .addCase(AccountActions.passwordChanged, (state, { payload }) => {
      state.user = payload.user;
    })
    .addCase(AccountActions.userUpdated, (state, { payload }) => {
      state.user = payload;
    });

  // AuthenticationActions
  builder.addMatcher(
    isAnyOf(AuthenticationActions.verifyAuthentication.fulfilled, AuthenticationActions.authenticationFailed),
    (state) => {
      state.isFetching = false;
    },
  );
});
