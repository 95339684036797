import type {
  ButtonHTMLAttributes,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  MouseEventHandler,
  MutableRefObject,
} from 'react';
import { forwardRef, useEffect, useRef } from 'react';

import styled from 'styled-components';

import { transitionDefault } from '@feather/animation';
import cssVariables from '@feather/theme/cssVariables';

export type HTMLToggleElement = HTMLButtonElement;

export interface ToggleProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onChange' | 'onClick'> {
  name?: string;
  checked?: boolean;
  autoFocus?: boolean;
  onClick?: (checked: boolean, event: MouseEvent<HTMLToggleElement>) => void;
  onChange?: (checked: boolean, event: MouseEvent<HTMLToggleElement> | KeyboardEvent<HTMLToggleElement>) => void;
  onMouseUp?: ButtonHTMLAttributes<HTMLToggleElement>['onMouseUp'];
}

const ToggleHandle = styled.div`
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 6px;
  transform: translateX(4px);
  transition: 0.2s ${transitionDefault};
  transition-property: background, transform;
  will-change: background, transform;
`;

const ToggleButton = styled.button`
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 40px;
  min-width: 40px;
  height: 20px;
  font-size: 0;
  line-height: 0;
  background: ${cssVariables('neutral-5')};
  border-radius: 10px;
  border: 1px solid ${cssVariables('neutral-5')};
  cursor: pointer;
  transition: 0.2s ${transitionDefault};
  transition-property: background, box-shadow;
  will-change: background, box-shadow;
  outline: 0;

  &[aria-checked='true'] {
    background: ${cssVariables('purple-7')};
    border-color: ${cssVariables('purple-7')};

    ${ToggleHandle} {
      background: #fff;
      transform: translateX(23px);
    }
  }

  &:focus:not(:active) {
    border-color: ${cssVariables('neutral-3')};
    box-shadow: 0 0 0 2px ${cssVariables('purple-7')};

    &[aria-checked='true'] {
      border-color: #fff;
    }
  }

  &:disabled {
    background: ${cssVariables('neutral-2')};
    border-color: ${cssVariables('neutral-2')};
    cursor: not-allowed;

    &[aria-checked='true'] {
      background: ${cssVariables('purple-3')};
      border-color: ${cssVariables('purple-3')};

      ${ToggleHandle} {
        background: #fff;
      }
    }

    ${ToggleHandle} {
      background: ${cssVariables('content-disabled')};
    }
  }
`;

export const Toggle = forwardRef<HTMLToggleElement, ToggleProps>(
  ({ name, checked, autoFocus, disabled, onClick, onChange, onMouseUp, ...restProps }, ref) => {
    const toggleRef = useRef<HTMLToggleElement | null>(null);

    const focus = () => {
      toggleRef.current?.focus();
    };

    const blur = () => {
      toggleRef.current?.blur();
    };

    const onCheckedChange: ToggleProps['onChange'] = (nextChecked, event) => {
      event.preventDefault();
      if (disabled) {
        return;
      }

      if (nextChecked !== undefined && checked !== nextChecked) {
        onChange?.(nextChecked, event);
      }
    };

    const handleClick: MouseEventHandler<HTMLToggleElement> = (event) => {
      event.preventDefault();

      const nextChecked = !checked;
      onCheckedChange(nextChecked, event);
      onClick?.(nextChecked, event);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLToggleElement> = (event) => {
      event.preventDefault();

      switch (event.key) {
        case 'Left': // IE/Edge specific value
        case 'ArrowLeft':
          onCheckedChange(false, event);
          return;

        case 'Right': // IE/Edge specific value
        case 'ArrowRight':
          onCheckedChange(true, event);
          return;

        case 'Enter':
        case ' ':
          onCheckedChange(!checked, event);
          return;

        case 'Esc': // IE/Edge specific value
        case 'Escape':
          blur();
          return;

        default:
          return;
      }
    };

    // Handle auto focus when click toggle
    const handleMouseUp: MouseEventHandler<HTMLToggleElement> = (event) => {
      blur();
      onMouseUp?.(event);
    };

    useEffect(() => {
      if (autoFocus && !disabled) {
        focus();
      }
    }, [autoFocus, disabled]);

    return (
      <ToggleButton
        ref={(node) => {
          toggleRef.current = node;
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            (ref as MutableRefObject<HTMLToggleElement | null>).current = node;
          }
        }}
        type="button"
        role="switch"
        name={name}
        aria-checked={checked}
        disabled={disabled}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onMouseUp={handleMouseUp}
        {...restProps}
      >
        {checked ? 'On' : 'Off'}
        <ToggleHandle />
      </ToggleButton>
    );
  },
);
