import { create } from 'zustand';

interface AeroAvailabilityStoreState {
  isAeroAvailable: boolean;
}

interface AeroAvailabilityStoreActions {
  setIsAeroAvailable: (isAeroAvailable: AeroAvailabilityStoreState['isAeroAvailable']) => void;
}

type AeroAvailabilityStore = AeroAvailabilityStoreState & AeroAvailabilityStoreActions;

const initialState: AeroAvailabilityStoreState = {
  isAeroAvailable: false,
};

export const useAeroAvailabilityStore = create<AeroAvailabilityStore>()((set) => ({
  ...initialState,
  setIsAeroAvailable: (isAeroAvailable) => set(() => ({ isAeroAvailable })),
}));
