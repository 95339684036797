import { useEffect, useRef, useState } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { getLocalTimeZone } from '@internationalized/date';
import { useSelect } from 'downshift';
import type { PopperChildrenProps, PopperProps } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';

import { DropdownToggleButton } from '@feather/components/dropdown';
import { elevation } from '@feather/elevation';
import { cssVariables } from '@feather/theme';
import { ZIndexes } from '@feather/zIndexes';
import { uuid } from '@utils';

import { ErrorMessage } from '../dropdown/components';
import { ToggleText } from './ToggleText';
import Calendar from './components/Calendar';
import type { MonthPickerProps } from './components/Calendar';
import type { DatePickerCommonProps } from './types';
import { getDefaultMenuProps, getDefaultToggleButtonProps, useDefaultDateFormatter } from './utils';

const CalendarWrapper = styled.div`
  border-radius: 4px;
  ${elevation.popover};
  overflow: hidden;
  z-index: ${ZIndexes.dropdownMenu};
  background: #ffffff;
  padding: 12px 16px 16px 16px;
`;

export type DatePickerProps = DatePickerCommonProps & {
  className?: string;
  date: CalendarDate | null;
  onChange?: (date?: CalendarDate) => void;
  placeholder?: string;
  popperProps?: Partial<PopperProps>;
  monthPickerProps?: MonthPickerProps;
  readOnly?: boolean;
  error?: string;
};
const DatePicker = ({
  className,
  date,
  disabled,
  readOnly = false,
  enableOutsideDays,
  formatDate,
  maxDate,
  minDate,
  onChange,
  placement = 'bottom-start',
  placeholder = 'Select date',
  popperProps,
  size = 'medium',
  monthPickerProps,
  timeZone = getLocalTimeZone(),
  error,
}: DatePickerProps) => {
  const { portalId: monthPickerPortalId, show: monthPickerShow } = monthPickerProps ?? {};

  const [portalId, setPortalId] = useState(monthPickerPortalId ?? uuid());

  useEffect(() => {
    setPortalId(monthPickerPortalId ?? uuid());
  }, [monthPickerPortalId]);

  const { closeMenu, getMenuProps, getToggleButtonProps, isOpen, toggleMenu } = useSelect({ items: [] });
  const scheduleUpdateRef = useRef<PopperChildrenProps['scheduleUpdate']>();
  const defaultDateFormatter = useDefaultDateFormatter();

  const handleChange = (value: CalendarDate) => {
    onChange?.(value);
    closeMenu();
  };

  useEffect(() => {
    scheduleUpdateRef.current?.();
  }, [date, size]);

  const toggleButtonProps = getToggleButtonProps(getDefaultToggleButtonProps(toggleMenu));
  const { ref: menuPropsRef, ...menuProps } = getMenuProps(getDefaultMenuProps(closeMenu));

  // Remove onMouseUp event from menuProps when monthPicker is enabled to propagate onMouseUp event to MonthPicker for closing dropdown
  const menuPropsForCalendar = monthPickerShow ? { ...menuProps, onMouseUp: undefined } : menuProps;

  return (
    <>
      <Manager>
        <Reference innerRef={toggleButtonProps.ref}>
          {({ ref }) => {
            return (
              <div>
                <DropdownToggleButton
                  {...toggleButtonProps}
                  className={className}
                  disabled={disabled}
                  isPlaceholder={!date}
                  ref={ref}
                  size={size}
                  variant="default"
                  aria-pressed={isOpen}
                  readOnly={readOnly}
                  css={error ? `border-color: ${cssVariables('border-negative')}` : ''}
                >
                  <ToggleText size={size}>
                    {date ? (formatDate ?? defaultDateFormatter)(date, timeZone) : placeholder}
                  </ToggleText>
                </DropdownToggleButton>
                {typeof error === 'string' && error && <ErrorMessage>{error}</ErrorMessage>}
              </div>
            );
          }}
        </Reference>
        <Popper placement={placement} {...popperProps} innerRef={menuPropsRef}>
          {({ ref, style, scheduleUpdate }) => {
            scheduleUpdateRef.current = scheduleUpdate;
            return (
              <CalendarWrapper
                {...menuPropsForCalendar}
                ref={ref}
                style={{ ...style, ...(isOpen ? null : { opacity: 0, pointerEvents: 'none' }) }}
              >
                <Calendar
                  monthPickerProps={monthPickerProps}
                  isDisabled={readOnly || disabled}
                  maxValue={maxDate ?? undefined}
                  minValue={minDate ?? undefined}
                  onChange={handleChange}
                  showOutsideVisibleRange={enableOutsideDays}
                  timeZone={timeZone}
                  value={date ?? null}
                />
              </CalendarWrapper>
            );
          }}
        </Popper>
        <div id={portalId} />
      </Manager>
    </>
  );
};

export default DatePicker;
