import { Suspense, lazy } from 'react';

import { Navigate, Route, Routes, createRoutesFromElements } from 'react-router-dom';

import config from '@/config';
import { Outlet, createBrowserRouter } from '@/router';
import InvitationTokensRedirect from '@common/containers/InvitationTokensRedirect';
import RouteErrorBoundary from '@common/containers/RouteErrorBoundary';
import RootLayout from '@common/containers/layout/RootLayout';
import { SpinnerFull } from '@feather/components/spinner/spinnerFull';
import useAeroMode from '@hooks/useAeroMode';

const { BUILD_MODE } = config;

const SettingsRoutes = lazy(() => import('@common/containers/settings/settingsRoutes'));

const AuthRoute = lazy(() => import('./AuthRoute'));
const GuideToDesktop = lazy(() => import('@common/containers/GuideToDesktop'));
const Integrations = lazy(() => import('./integrations'));
const NavigationLayout = lazy(() => import('@common/containers/layout/navigationLayout'));
const Activate = lazy(() => import('@common/containers/Activate'));
const App = lazy(() => import('@core/containers/app'));
const Auth = lazy(() => import('@common/containers/auth'));
const AwsMarketplace = lazy(() => import('@common/containers/AwsMarketplace'));
const Home = lazy(() => import('@common/containers/home'));
const Onboarding = lazy(() => import('@common/containers/Onboarding'));
const AIChatbotOnboarding = lazy(() => import('@chat/aibots/containers/Onboarding'));
const AIChatbotOnboardingV2 = lazy(() => import('@chat/aibots/containers/OnboardingV2'));
const AIChatbotWidgetPreview = lazy(() => import('@chat/aibots/containers/WidgetPreview'));
const OrganizationStatusScreen = lazy(() => import('@common/containers/home/OrganizationStatusScreen'));
const PageNotFound = lazy(() => import('@common/containers/PageNotFound'));

const CreateOrganization = lazy(() => import('@common/containers/Onboarding/CreateOrganization'));
const CreateApplication = lazy(() => import('@common/containers/Onboarding/CreateApplication'));

const Account = lazy(() => import('@common/containers/account'));
const AccountProfile = lazy(() => import('@common/containers/account/profile'));
const AccountSecurity = lazy(() => import('@common/containers/account/security'));

/* Org settings */
const Settings = lazy(() => import('@common/containers/settings'));
const SignInWithSso = lazy(() => import('@common/containers/auth/signInWithSso'));

const Aero = lazy(() => import('@aero/index'));

const AppRoutes = () => {
  const { isAeroMode } = useAeroMode();

  return isAeroMode ? (
    <Routes>
      <Route path="">
        <Route path="*" element={<Aero />} />
        <Route path="" element={<Aero />} />
      </Route>
    </Routes>
  ) : (
    <Routes>
      <Route
        path=""
        element={
          <Suspense fallback={<SpinnerFull />}>
            <AuthRoute>
              <Outlet />
            </AuthRoute>
          </Suspense>
        }
      >
        <Route path="ai-chatbots/onboarding/v2">
          <Route path="steps/*" element={<AIChatbotOnboardingV2 />} />
          <Route path="" element={<Navigate to="steps" replace={true} />} />
        </Route>

        <Route path=":appId/guide-to-desktop" element={<GuideToDesktop />} />
        <Route path=":appId/ai-chatbots/preview/:botId" element={<AIChatbotWidgetPreview />} />
        <Route path=":appId/ai-chatbots/onboarding">
          <Route path="steps/*" element={<AIChatbotOnboarding />} />
          <Route path="" element={<Navigate to="steps" replace={true} />} />
        </Route>
        <Route path="onboarding/*" element={<Onboarding />}>
          <Route path="organization" element={<CreateOrganization />} />
          <Route path="application" element={<CreateApplication />} />
          <Route path="*" element={<Navigate to="organization" replace={true} />} />
        </Route>
        <Route path="*" element={<NavigationLayout />}>
          <Route index={true} element={<Home />} />
          <Route path="account/*" element={<Account />}>
            <Route path="profile" element={<AccountProfile />} />
            <Route path="security" element={<AccountSecurity />} />
            <Route path="*" element={<Navigate to="profile" replace={true} />} />
          </Route>
          <Route path="settings" element={<Settings />}>
            <Route path="*" element={<SettingsRoutes />} />
          </Route>
          <Route path="deactivated" element={<OrganizationStatusScreen />} />
          <Route path=":appId/*" element={<App />} />
        </Route>
      </Route>
    </Routes>
  );
};

/** @knipignore */
export const routes = createRoutesFromElements(
  <Route path="/" element={<RootLayout />} errorElement={<RouteErrorBoundary />}>
    <Route path="page-not-found" element={<PageNotFound />} />
    <Route path="integrations/*" element={<Integrations />} />
    {/* Task key: INVITATION_TOKEN_BINDER_FOR_AMPLIFY */}
    {/* Globally search with the task key above to find related code */}
    {BUILD_MODE !== 'production' && <Route path="tokens" element={<InvitationTokensRedirect />} />}
    <Route path="auth/*" element={<Auth />} />
    <Route path="sso/*" element={<SignInWithSso />} /> {/* Auth0 redirects user to this route not /auth/sso */}
    <Route path="activate/*" element={<Activate />} />
    <Route path="aws-marketplace/*" element={<AwsMarketplace />} />
    <Route path="*" element={<AppRoutes />} />
    <Route path="" element={<AppRoutes />} />
  </Route>,
);

export const browserRouter = createBrowserRouter(routes);
