import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const EmojiTerribleSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      <path
        d="M2.083 20c0-9.895 8.022-17.917 17.917-17.917 9.895 0 17.917 8.022 17.917 17.917 0 9.895-8.022 17.917-17.917 17.917-9.895 0-17.917-8.022-17.917-17.917Z"
        fill="url(#icon_csat_terrible_linear_gradient)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.583 29.333a1.25 1.25 0 0 0 1.75-.249A7.07 7.07 0 0 1 20 26.25a7.07 7.07 0 0 1 5.667 2.834 1.25 1.25 0 0 0 2-1.502A9.57 9.57 0 0 0 20 23.75a9.57 9.57 0 0 0-7.666 3.832 1.25 1.25 0 0 0 .249 1.75Z"
        fill="#141B34"
      />
      <path
        d="M28.445 13.77a1.25 1.25 0 0 1-.223 2.49s-.193-.01-.295-.011a6.993 6.993 0 0 0-.804.034 4.928 4.928 0 0 0-.943.183l.125.394c.475 1.508-.795 2.721-2.099 2.721-.701 0-1.374-.32-1.776-.895a1.893 1.893 0 0 1-.022-2.152c.343-.52.837-1.15 1.488-1.668.94-.746 2.148-.985 2.971-1.07a9.981 9.981 0 0 1 1.578-.025ZM11.555 13.77a1.25 1.25 0 0 0 .223 2.49s.194-.01.295-.011c.204-.003.489.002.804.034.336.035.658.095.943.183l-.124.394c-.476 1.508.794 2.721 2.098 2.721.702 0 1.374-.32 1.776-.895a1.893 1.893 0 0 0 .022-2.152c-.343-.52-.837-1.15-1.488-1.668-.94-.746-2.148-.985-2.971-1.07a9.98 9.98 0 0 0-1.578-.025Z"
        fill="#141B34"
      />
      <defs>
        <linearGradient
          id="icon_csat_terrible_linear_gradient"
          x1="20"
          y1="2.083"
          x2="20"
          y2="37.917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2D1D" />
          <stop offset="1" stopColor="#FFA540" />
        </linearGradient>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const EmojiTerrible = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={EmojiTerribleSvg} {...props} />
));
EmojiTerrible.displayName = 'EmojiTerrible';
export default EmojiTerrible;
