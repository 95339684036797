import type { FC, MouseEventHandler, ReactNode, TransitionEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import styled, { css } from 'styled-components';

import * as Icons from '@feather/components/icons';
import cssVariables from '@feather/theme/cssVariables';

import { transitionDefault } from '../../animation';
import type { ScrollBarRef } from '../../types';
import type {
  CreateLeftNavigationBarItem,
  LeftNavigationBarDividerInterface,
  LeftNavigationBarItemInterface,
  LeftNavigationBarProps,
  LeftNavigationBarSubItemInterface,
} from '../../types/components/leftNavigationBar';
import { LeftNavigationBarItemType } from '../../types/components/leftNavigationBar';
import { ScrollBar } from '../ScrollBar';
import { LNBMenuItem } from './LNBMenuItem';
import { COLLAPSED_ANIMATION_DURATION_SECOND, COLLAPSE_THROTTLE_HOVER_TIME_MS, LabelItem, MenuItem } from './styled';

const DEFAULT_WIDTH = 216;
const COLLAPSED_WIDTH = 52;

interface LeftNavigationBarType extends FC<LeftNavigationBarProps> {
  collapseAnimationDurationSecond: number;
  defaultWidth: number;
  collapsedWidth: number;
}

const ExpandIcon = styled(Icons.ShiftRight)`
  margin: auto;
`;
const CollapseIcon = styled(Icons.ShiftLeft)`
  margin: auto;
`;

const ExpandCollapseMenuItem = styled(MenuItem)<{ position: 'top' | 'bottom' }>`
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 0;
  ${({ position }) => (position === 'top' ? 'top: 10px' : 'bottom: 10px')};
  padding-left: 0;
  padding-right: 0;
  right: 10px;
  z-index: 1;
`;

const Container = styled.nav<{
  $width?: number;
  $isCollapsed: boolean;
  $isHovered: boolean;
  $isExpandCollapseButtonHidden: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ $width = DEFAULT_WIDTH }) => $width}px;
  background-color: ${cssVariables('neutral-1')};
  overflow-x: hidden;
  position: relative;

  transition: ${COLLAPSED_ANIMATION_DURATION_SECOND}s ${transitionDefault};
  transition-property: width, box-shadow;
  will-change: width, box-shadow;

  ${(props) =>
    props.$isCollapsed &&
    css`
      ${LabelItem} {
        color: ${props.$isHovered && !props.$isExpandCollapseButtonHidden ? cssVariables('content-3') : 'transparent'};
        height: ${props.$isExpandCollapseButtonHidden ? '0' : '36px'};
        padding: ${props.$isExpandCollapseButtonHidden ? '0' : '8px 16px 8px 20px'};
      }
    `}

  ${(props) =>
    props.$isCollapsed &&
    props.$isHovered &&
    css`
      box-shadow: 0px 16px 24px 2px rgba(13, 13, 13, 0.12), 0px 6px 30px 5px rgba(13, 13, 13, 0.08),
        0px 6px 10px -5px rgba(13, 13, 13, 0.04);
    `}
`;

const MenuScrollBar = styled(ScrollBar)`
  padding: 8px 0 0;
  height: auto;
`;

export const LeftNavigationBarDivider: LeftNavigationBarDividerInterface = {
  type: LeftNavigationBarItemType.Divider,
};

export const createLeftNavigationBarSubItem: CreateLeftNavigationBarItem<LeftNavigationBarSubItemInterface> = (
  params,
) => ({
  type: LeftNavigationBarItemType.SubItem,
  ...params,
});

export const createLeftNavigationBarItem: CreateLeftNavigationBarItem<LeftNavigationBarItemInterface> = (params) => ({
  type: LeftNavigationBarItemType.Item,
  ...params,
});

export const LeftNavigationBar: LeftNavigationBarType = ({
  className,
  isCollapsed = false,
  activeGroup,
  items,
  activeKey,
  onExpandCollapseButtonClick: onExpandCollapseButtonClickProp,
  isExpandCollapseButtonHidden = false,
  togglePosition = 'top',
  children,
}) => {
  const [expandedGroup, setExpandedGroup] = useState<string | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const menuScrollBarRef = useRef<ScrollBarRef>(null);
  const [expandCollapseButton, setExpandCollapseButton] = useState<ReactNode>(null);
  const [width, setWidth] = useState(DEFAULT_WIDTH);
  const [isHovered, setIsHovered] = useState(false);
  const isHoveredRef = useRef(isHovered);

  const onMouseLeaveContainer = useCallback(() => {
    if (!isCollapsed) return;
    isHoveredRef.current = false;
    setIsHovered(false);
    setWidth(COLLAPSED_WIDTH);
  }, [isCollapsed]);

  const updateScrollBarRef = useMemo(
    () =>
      debounce(() => {
        menuScrollBarRef.current && menuScrollBarRef.current.update();
      }, 250),
    [],
  );

  const resizeObserverRef = useRef(
    new ResizeObserver(() => {
      updateScrollBarRef();
    }),
  );

  // This hook kicks in when entering the forceCollapsed route such as /open-channels/:channelID, desk/tickets/:ticketID
  useEffect(() => {
    if (isCollapsed) {
      setWidth(COLLAPSED_WIDTH);
      setIsHovered(false);
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (activeGroup) {
      setExpandedGroup(activeGroup);
    }
  }, [activeGroup]);

  useEffect(() => {
    const { current: resizeObserver } = resizeObserverRef;
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, []);

  const onExpandCollapseButtonClick = useCallback(() => {
    onExpandCollapseButtonClickProp && onExpandCollapseButtonClickProp(!isCollapsed);
    setWidth(COLLAPSED_WIDTH);
    setIsHovered(false);
  }, [isCollapsed, onExpandCollapseButtonClickProp]);

  useEffect(() => {
    const ExpandCollapseIcon = isCollapsed ? ExpandIcon : CollapseIcon;
    const expandCollapseButton = (
      <ExpandCollapseMenuItem
        data-test-id="ExpandCollapseButton"
        position={togglePosition}
        role="link"
        onClick={onExpandCollapseButtonClick}
      >
        <ExpandCollapseIcon size={20} color={cssVariables('neutral-7')} />
      </ExpandCollapseMenuItem>
    );

    if (isFirstRender.current || !isCollapsed) {
      setExpandCollapseButton(expandCollapseButton);
      return;
    }

    setTimeout(() => setExpandCollapseButton(expandCollapseButton), COLLAPSED_ANIMATION_DURATION_SECOND * 1000);
  }, [isCollapsed, onExpandCollapseButtonClick]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.willChange = 'content';
    }
    setWidth(isCollapsed ? COLLAPSED_WIDTH : DEFAULT_WIDTH);
  }, [isCollapsed]);

  const onTransitionEnd = useCallback((event: TransitionEvent<HTMLDivElement>) => {
    event.currentTarget.style.willChange = 'auto';
  }, []);

  const handleMouseEnterOnItem: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    isHoveredRef.current = true;

    if (isCollapsed) {
      setTimeout(() => {
        if (!isHoveredRef.current) return;
        setWidth(DEFAULT_WIDTH);
        setIsHovered(true);
      }, COLLAPSE_THROTTLE_HOVER_TIME_MS);
    }
  }, [isCollapsed]);

  return (
    <Container
      ref={containerRef}
      className={className}
      $isCollapsed={isCollapsed}
      $isHovered={isHovered}
      $isExpandCollapseButtonHidden={isExpandCollapseButtonHidden}
      $width={width}
      onTransitionEnd={onTransitionEnd}
      onMouseLeave={onMouseLeaveContainer}
    >
      {!isExpandCollapseButtonHidden && expandCollapseButton}
      <MenuScrollBar ref={menuScrollBarRef} options={{ suppressScrollX: true }}>
        {items.map((item, index) => {
          const key =
            item.type === (LeftNavigationBarItemType.Item || LeftNavigationBarItemType.SubItem)
              ? item.key
              : `${item.type}__${index}`;
          return (
            <LNBMenuItem
              type={item.type}
              key={key}
              item={item}
              activeKey={activeKey}
              isLNBCollapsed={isCollapsed}
              isLNBHovered={isHovered}
              collapsedLNBWidth={COLLAPSED_WIDTH}
              activeGroup={activeGroup}
              expandedGroup={expandedGroup}
              setExpandedGroup={setExpandedGroup}
              onEnter={handleMouseEnterOnItem}
            />
          );
        })}
      </MenuScrollBar>
      {children?.({ expandedGroup })}
    </Container>
  );
};

LeftNavigationBar.collapseAnimationDurationSecond = COLLAPSED_ANIMATION_DURATION_SECOND;
LeftNavigationBar.defaultWidth = DEFAULT_WIDTH;
LeftNavigationBar.collapsedWidth = COLLAPSED_WIDTH;
