import type { ReactNode } from 'react';

import type { DownshiftProps } from 'downshift';
import Downshift from 'downshift';
import type { PopperProps } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import * as hideOutlineEventListeners from '@feather/components/button/hideOutlineEventListeners';
import { elevation } from '@feather/elevation';
import cssVariables from '@feather/theme/cssVariables';
import type { GlobalNavigationBarDropdownMenuItem, GlobalNavigationBarProps } from '@feather/types';
import { Subtitles } from '@feather/typography';
import { ZIndexes } from '@feather/zIndexes';

import { Avatar, AvatarType } from '../avatar';
import { generateButtonContainerStyle } from '../button';

interface CurrentUserDropdownProps
  extends Pick<GlobalNavigationBarProps, 'user' | 'organization' | 'userMenus' | 'organizationMenus'> {
  className?: string;
  downshiftProps?: Partial<DownshiftProps<any>>;
  popperProps?: Partial<Omit<PopperProps, 'children'>>;
  theme?: 'default' | 'dark';
}

type MenuItem = CurrentUserDropdownProps['userMenus'][number] | CurrentUserDropdownProps['organizationMenus'][number];

const CurrentUserDropdownToggle = styled.button<{ theme: 'default' | 'dark' }>`
  ${({ theme }) =>
    theme === 'default'
      ? generateButtonContainerStyle({
          contentColor: 'white',
          disabledContentColor: 'white',
          bgColor: 'transparent',
          hoverBgColor: cssVariables('purple-8'),
          activeBgColor: cssVariables('purple-9'),
          pressedBgColor: cssVariables('purple-8'),
          disabledBgColor: 'transparent',
          borderColor: 'transparent',
          disabledBorderColor: 'transparent',
          focusOutlineColor: 'white',
        })
      : generateButtonContainerStyle({
          contentColor: 'white',
          disabledContentColor: 'white',
          bgColor: 'transparent',
          hoverBgColor: cssVariables('bg-inverse-overlay-1'),
          activeBgColor: cssVariables('bg-inverse-overlay-2'),
          pressedBgColor: cssVariables('bg-inverse-overlay-2'),
          disabledBgColor: 'transparent',
          borderColor: 'transparent',
          disabledBorderColor: 'transparent',
          focusOutlineColor: 'white',
        })};

  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0 8px;
`;

const OrganizationName = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  margin-right: 8px;
  font-weight: 500;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 248px;
  border-radius: 4px;
  background-color: white;
  ${elevation.popover}
  z-index: ${ZIndexes.dropdownMenu};
  overflow: hidden;
`;

const MenuOrganizationSection = styled.div`
  border-bottom: 1px solid ${cssVariables('neutral-3')};
  padding-bottom: 8px;
`;

const MenuUserSection = styled.div`
  padding-bottom: 8px;
`;

const ProfileGrid = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 21px 1fr;
  grid-gap: 2px 12px;
  grid-template-areas:
    'avatar name'
    'avatar key';
  align-content: center;
  padding: 8px 16px;
  margin: 8px 0;
`;

const ProfileGridAvatarArea = styled.div`
  grid-area: avatar;
`;

const ProfileGridNameArea = styled.div`
  grid-area: name;
  align-self: end;
  font-size: 14px;
  line-height: 20px;
  color: ${cssVariables('neutral-10')};
  font-weight: 600;
  letter-spacing: -0.1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProfileGridKeyArea = styled.div`
  grid-area: key;
  align-self: start;
  font-size: 12px;
  line-height: 16px;
  color: ${cssVariables('neutral-7')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const menuItemCSS = css`
  display: block;
  width: 100%;
  padding: 6px 16px;
  background-color: white;
  border: 0;
  ${Subtitles['subtitle-01']}
  color: ${cssVariables('neutral-10')} !important;
  text-decoration: none !important;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const MenuItem = styled.button`
  ${menuItemCSS}
`;

const MenuItemLink = styled.a`
  ${menuItemCSS}
`;

const MenuItemReactRouterLink = styled(Link)`
  ${menuItemCSS}
`;

const SectionDivider = styled.div`
  margin-top: 8px;
  border-top: 1px solid ${cssVariables('neutral-3')};
  padding-top: 8px;
`;

const onDownshiftSelect = (selectedItem: MenuItem | null) => {
  if (selectedItem && selectedItem.onClick) {
    selectedItem.onClick();
  }
};

export const CurrentUserDropdown = ({
  className,
  user,
  organization,
  userMenus,
  organizationMenus,
  downshiftProps,
  popperProps,
  theme = 'default',
}: CurrentUserDropdownProps) => {
  return (
    <Downshift itemToString={(item) => (item ? item.label : '')} onSelect={onDownshiftSelect} {...downshiftProps}>
      {({ getItemProps, getMenuProps, isOpen, highlightedIndex, getToggleButtonProps }) => {
        const renderMenuItem =
          (offset: number = 0) =>
          (item: GlobalNavigationBarDropdownMenuItem, index) => {
            const itemProps = getItemProps({
              key: item.label,
              index: index + offset,
              item,
              style: {
                backgroundColor: highlightedIndex === index + offset ? cssVariables('neutral-2') : 'white',
              },
            });
            let component: ReactNode = <></>;

            if (item.href) {
              if (item.useReactRouterLink) {
                component = (
                  <MenuItemReactRouterLink {...itemProps} to={item.href} target={item.target}>
                    {item.label}
                  </MenuItemReactRouterLink>
                );
              } else {
                component = (
                  <MenuItemLink {...itemProps} href={item.href} target={item.target}>
                    {item.label}
                  </MenuItemLink>
                );
              }
            } else {
              component = <MenuItem {...itemProps}>{item.label}</MenuItem>;
            }
            return item.useDivider ? <SectionDivider key={itemProps.key}>{component}</SectionDivider> : component;
          };

        /**
         * { suppressRefError: true } is added here because I can confirm the component has been working without
         * problems, and the warnings generated by `refKey` check make debugging harder. However, [the Downshift
         * documentation](https://github.com/downshift-js/downshift#getmenuprops) does not recommend to use this option,
         * and it's best to make the implementation follow the original pattern.
         */
        const { ref: menuPropsRef, ...menuProps } = getMenuProps({}, { suppressRefError: true });

        return (
          <div className={className}>
            <Manager>
              <Reference>
                {({ ref }) => {
                  return (
                    <CurrentUserDropdownToggle
                      ref={ref}
                      aria-pressed={isOpen}
                      theme={theme}
                      {...getToggleButtonProps()}
                      {...hideOutlineEventListeners}
                    >
                      <OrganizationName>{organization.name}</OrganizationName>
                      <Avatar
                        type={AvatarType.Member}
                        profileID={user.email}
                        imageUrl={user.profileImageURL}
                        size={32}
                      />
                    </CurrentUserDropdownToggle>
                  );
                }}
              </Reference>
              <Popper
                placement="bottom-end"
                modifiers={{ flip: { behavior: ['bottom'] } }}
                innerRef={menuPropsRef}
                {...popperProps}
              >
                {({ ref, style }) => {
                  return (
                    <MenuContainer {...menuProps} ref={ref} style={style}>
                      {isOpen && (
                        <>
                          <MenuOrganizationSection>
                            <ProfileGrid>
                              <ProfileGridAvatarArea>
                                <Avatar
                                  type={AvatarType.Organization}
                                  imageUrl={organization.profileImageURL}
                                  profileID={organization.key}
                                  size={40}
                                />
                              </ProfileGridAvatarArea>
                              <ProfileGridNameArea>{organization.name}</ProfileGridNameArea>
                              <ProfileGridKeyArea>{organization.key}</ProfileGridKeyArea>
                            </ProfileGrid>
                            {organizationMenus.map(renderMenuItem())}
                          </MenuOrganizationSection>
                          <ProfileGrid>
                            <ProfileGridAvatarArea>
                              <Avatar
                                type={AvatarType.Member}
                                profileID={user.email}
                                imageUrl={user.profileImageURL}
                                size={40}
                              />
                            </ProfileGridAvatarArea>
                            <ProfileGridNameArea>{user.name}</ProfileGridNameArea>
                            <ProfileGridKeyArea>{user.email}</ProfileGridKeyArea>
                          </ProfileGrid>
                          <MenuUserSection>{userMenus.map(renderMenuItem(organizationMenus.length))}</MenuUserSection>
                        </>
                      )}
                    </MenuContainer>
                  );
                }}
              </Popper>
            </Manager>
          </div>
        );
      }}
    </Downshift>
  );
};
