import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import QueryKeyPrefix from '@constants/queryKey';
import { fetchEnabledFeaturesQueryFn } from '@core/api/queryFn';
import useApplication from '@hooks/useApplication';
import useIsAuthenticated from '@hooks/useIsAuthenticated';
import useOrganization from '@hooks/useOrganization';

import convertEnabledFeaturesObjectToFeatureKeys from './convertEnabledFeaturesObjectToFeatureKeys';

/**
 * @FIXME: https://sendbird.atlassian.net/browse/DFE-1504
 * @description Multiple enabledFeatures in different places
 */
const useSelfServiceFeatures = () => {
  const { isSelfService, isOrgActive } = useOrganization((current) => ({
    isSelfService: !!current.is_self_serve,
    isOrgActive: !isEmpty(current) && current.status === 'ACTIVE',
  }));
  const authenticated = useIsAuthenticated();
  const application = useApplication();

  const appId = application?.app_id;

  const { isLoading: isLoadingFeatures, data: selfServiceFeatures = [] } = useQuery<EnabledFeatures, unknown, string[]>(
    {
      queryKey: [QueryKeyPrefix.AppFetchEnabledFeatures, appId],
      queryFn: fetchEnabledFeaturesQueryFn,
      enabled: !!(authenticated && isOrgActive && isSelfService && appId),
      select: (data) => convertEnabledFeaturesObjectToFeatureKeys(data),
    },
  );

  const isLoading = !authenticated || !application || isLoadingFeatures;

  return useMemo(() => ({ isLoading, result: selfServiceFeatures }), [isLoading, selfServiceFeatures]);
};

export default useSelfServiceFeatures;
