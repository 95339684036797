import { createContext } from 'react';

import is from '@sindresorhus/is';
import type { QueryObserverBaseResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { useAuth } from '@/redux/hooks/auth';
import { fetchSupportPlanSubscriptionInfoQueryFn } from '@common/api/queryFn';
import { SubscriptionProduct } from '@constants';
import QueryKeyPrefix from '@constants/queryKey';
import useOrganization from '@hooks/useOrganization';

type SupportPlanContextType = {
  isLoading: boolean;
  isLoaded: boolean;
  current: SupportPlanSubscription | null;
  future: SupportPlanSubscription | null;
  refetch: QueryObserverBaseResult['refetch'];
};

export const SupportPlanContext = createContext<SupportPlanContextType>({
  isLoading: false,
  isLoaded: false,
  current: null,
  future: null,
  refetch: () => new Promise(() => {}),
});

export const SupportPlanContextProvider = ({ children }) => {
  const { authenticated } = useAuth();
  const organization = useOrganization();

  const {
    data: subscriptions,
    isLoading,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: [QueryKeyPrefix.OrgSubscriptionInfo, { product: SubscriptionProduct.Support }],
    queryFn: fetchSupportPlanSubscriptionInfoQueryFn,
    enabled: authenticated && is.nonEmptyObject(organization),
  });

  return (
    <SupportPlanContext.Provider
      value={{
        isLoading,
        isLoaded: isFetched,
        current: subscriptions?.current_subscription ?? null,
        future: subscriptions?.future_subscription ?? null,
        refetch,
      }}
    >
      {children}
    </SupportPlanContext.Provider>
  );
};
