import { captureException, captureMessage } from '@sentry/react';
import type { ScopeContext } from '@sentry/types';

type Extra = ScopeContext['extra'];
type Tags = ScopeContext['contexts']['tags'];

/**
 * You can specify a reason tag to search for a specific error in the Sentry dashboard.
 */
type Reason =
  /* Common */
  | 'Axios'
  | 'Axios - undefined error'
  | 'Axios - error.status is 401'
  | 'Epic'
  | 'Unclassified error in getErrorMessage'
  /* Chat */
  | 'Update extension'
  /* Calls */
  | 'Calls failover'
  /* Desk */
  | 'Desk error handler - without error code'
  | 'Desk error handler - with undefined error code'
  | 'Fetch Desk FAQ bot file request'
  /* Business Messaging */
  | 'Onboarding Activation'
  | 'Onboarding Presets'
  /* AI Agent */
  | 'AI Agent - parse manual document';

/**
 * Report an exception to Sentry. `error` must be either string or Error object.
 */
const logException = (error: unknown, options?: { reason?: Reason; tags?: Tags; context?: Extra }) => {
  const captureContext = {
    tags: { ...options?.tags, reason: options?.reason },
    extra: options?.context,
  };

  if (typeof error === 'string') {
    captureMessage(error, captureContext);
  } else {
    captureException(error, captureContext);
  }
};

export default logException;
