import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DetectSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      width="80"
      height="81"
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clip-path="url(#clip0_5245_30516)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M39.9667 26.7002C37.6287 26.7002 35.7334 28.5955 35.7334 30.9335C35.7334 33.2715 37.6287 35.1669 39.9667 35.1669C42.3047 35.1669 44.2001 33.2715 44.2001 30.9335C44.2001 28.5955 42.3047 26.7002 39.9667 26.7002ZM29.7334 30.9335C29.7334 25.2818 34.315 20.7002 39.9667 20.7002C45.6184 20.7002 50.2001 25.2818 50.2001 30.9335C50.2001 36.5852 45.6184 41.1669 39.9667 41.1669C34.315 41.1669 29.7334 36.5852 29.7334 30.9335Z"
          fill="#A6A6A6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.5402 18.4335L29.4525 20.5879C26.8313 23.2928 25.2314 26.9219 25.2314 30.9335C25.2314 34.9536 26.837 38.608 29.4416 41.268L31.5405 43.4115L27.2535 47.6092L25.1546 45.4657C21.4925 41.7257 19.2314 36.5801 19.2314 30.9335C19.2314 25.2784 21.4983 20.1742 25.1437 16.4124L27.2315 14.2581L31.5402 18.4335Z"
          fill="#A6A6A6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.54 10.4731L21.4322 12.6079C16.7623 17.3377 13.8975 23.7898 13.8975 30.9335C13.8975 38.0771 16.7623 44.5292 21.4322 49.259L23.54 51.3938L19.2704 55.6093L17.1627 53.4746C11.4326 47.671 7.89746 39.7231 7.89746 30.9335C7.89746 22.1438 11.4326 14.1959 17.1627 8.39236L19.2704 6.25757L23.54 10.4731Z"
          fill="#A6A6A6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42.9629 35.1663V73.4996H36.9629V35.1663H42.9629Z"
          fill="#A6A6A6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M52.7441 14.2573L54.843 16.4009C58.505 20.1408 60.7661 25.2865 60.7661 30.9331C60.7661 36.5881 58.4993 41.6923 54.8538 45.4541L52.7661 47.6085L48.4573 43.433L50.5451 41.2787C53.1663 38.5738 54.7661 34.9447 54.7661 30.9331C54.7661 26.9129 53.1605 23.2586 50.5559 20.5986L48.457 18.4551L52.7441 14.2573Z"
          fill="#A6A6A6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60.7276 6.25757L62.8354 8.39236C68.5654 14.1959 72.1006 22.1438 72.1006 30.9335C72.1006 39.7231 68.5654 47.671 62.8354 53.4746L60.7276 55.6093L56.458 51.3938L58.5658 49.259C63.2357 44.5292 66.1006 38.0771 66.1006 30.9335C66.1006 23.7898 63.2357 17.3377 58.5658 12.6079L56.458 10.4731L60.7276 6.25757Z"
          fill="#A6A6A6"
        />
      </g>
      <defs>
        <clipPath id="clip0_5245_30516">
          <rect width="80" height="80" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const Detect = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DetectSvg} {...props} />
));
Detect.displayName = 'Detect';
export default Detect;
