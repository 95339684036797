import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const DivideFilledSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 5.5C22 3.567 20.433 2 18.5 2C16.9145 2 15.5752 3.05426 15.1449 4.5H10.5V11H8.91946C8.57612 9.42741 7.17556 8.25 5.5 8.25C3.567 8.25 2 9.817 2 11.75C2 13.683 3.567 15.25 5.5 15.25C6.9926 15.25 8.26697 14.3157 8.77019 13H10.5V19.5H15.1449C15.5752 20.9457 16.9145 22 18.5 22C20.433 22 22 20.433 22 18.5C22 16.567 20.433 15 18.5 15C16.9145 15 15.5752 16.0543 15.1449 17.5H12.5V13V11V6.5H15.1449C15.5752 7.94574 16.9145 9 18.5 9C20.433 9 22 7.433 22 5.5Z"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const DivideFilled = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={DivideFilledSvg} {...props} />
));
DivideFilled.displayName = 'DivideFilled';
export default DivideFilled;
