import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const ZendeskSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M11.163 8.158v12.266H1L11.163 8.158zM11.163 3.758A5.074 5.074 0 0 1 6.087 8.83C3.283 8.831 1 6.561 1 3.758h10.163zM12.837 20.424a5.074 5.074 0 0 1 5.076-5.074 5.074 5.074 0 0 1 5.076 5.074H12.837zM12.837 16.024V3.757H23L12.837 16.024z" />
    </svg>
  )),
  { width: 24, height: 24 },
);
const Zendesk = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={ZendeskSvg} {...props} />
));
Zendesk.displayName = 'Zendesk';
export default Zendesk;
