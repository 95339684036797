import { memo, useMemo } from 'react';

import { CalendarDate } from '@internationalized/date';
import styled, { css } from 'styled-components';

import { Dropdown } from '@feather/components/dropdown';
import { Headings } from '@feather/typography';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const PickerContainer = styled.div<{ paddingPosition?: 'left' | 'right' }>`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ paddingPosition }) =>
    paddingPosition
      ? css`
    padding-${paddingPosition}: 32px;
  `
      : ''}
`;

const PickerToggle = styled.div`
  margin-left: 8px;
  ${Headings['heading-01']};
`;

const currentYear = new Date().getFullYear();
const months = MONTHS.map((label, value) => ({ label, value: value + 1 }));

export type PickerProps = {
  month: CalendarDate;
  onChangeIsOpen?: (isOpen: boolean) => void;
  onSelect: (value: CalendarDate) => void;
  paddingPosition?: 'left' | 'right';
  yearRange?: { beforeFromThisYear: number; afterFromThisYear: number };
  portalId?: string;
};

Array(30)
  .fill(0)
  .map((_, index) => {
    const value = currentYear - 15 + index;
    return { label: value, value };
  });

const MonthPicker = memo<PickerProps>(
  ({ month: value, yearRange, onChangeIsOpen, onSelect, paddingPosition, portalId }) => {
    const { beforeFromThisYear, afterFromThisYear } = yearRange ?? { beforeFromThisYear: 15, afterFromThisYear: 15 };

    const years = useMemo(() => {
      return Array(beforeFromThisYear + afterFromThisYear + 1)
        .fill(0)
        .map((_, index) => {
          const value = currentYear - beforeFromThisYear + index;
          return { label: value, value };
        });
    }, [beforeFromThisYear, afterFromThisYear]);

    return (
      <PickerContainer
        paddingPosition={paddingPosition}
        // For Calendar, we need to stop propagation of blur event to prevent closing the dropdown when month picker is blurred
        onBlur={(e) => {
          e.stopPropagation();
        }}
      >
        <Dropdown
          items={months}
          itemToElement={(item) => item.label}
          itemToString={(item) => item.label.slice(0, 3)}
          listMaxHeight={208}
          modifiers={{
            preventOverflow: { enabled: true, boundariesElement: 'viewport' },
          }}
          onItemSelected={(item) => {
            item && onSelect(new CalendarDate(value.year, item.value, 1));
          }}
          onStateChange={({ isOpen }) => {
            if (typeof isOpen === 'boolean') {
              onChangeIsOpen?.(isOpen);
            }
          }}
          placement="bottom-start"
          portalId={portalId}
          selectedItem={months[value.month - 1] ?? null}
          size="small"
          toggleRenderer={({ selectedItem }) => <PickerToggle>{selectedItem?.label.slice(0, 3)}</PickerToggle>}
          variant="inline"
        />

        <Dropdown
          isMenuScrollable={true}
          items={years}
          itemToElement={(item) => item.label}
          itemToString={(item) => String(item.label)}
          listMaxHeight={208}
          modifiers={{
            preventOverflow: { enabled: true, boundariesElement: 'viewport' },
          }}
          onItemSelected={(item) => {
            item && onSelect(new CalendarDate(item.value, value.month, 1));
          }}
          onStateChange={({ isOpen }) => {
            if (typeof isOpen === 'boolean') {
              onChangeIsOpen?.(isOpen);
            }
          }}
          placement="bottom-start"
          portalId={portalId}
          selectedItem={years.find((item) => item.value === value.year) ?? null}
          size="small"
          toggleRenderer={({ selectedItem }) => <PickerToggle>{selectedItem?.label}</PickerToggle>}
          variant="inline"
        />
      </PickerContainer>
    );
  },
);

export default MonthPicker;
