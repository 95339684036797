import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const WebsiteSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" ref={ref} aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M8.992 1.5C4.853 1.5 1.5 4.86 1.5 9c0 4.14 3.353 7.5 7.492 7.5 4.148 0 7.508-3.36 7.508-7.5 0-4.14-3.36-7.5-7.508-7.5zM14.19 6h-2.213a11.737 11.737 0 0 0-1.034-2.67A6.022 6.022 0 0 1 14.19 6zM9 3.03c.623.9 1.11 1.898 1.432 2.97H7.567C7.89 4.928 8.377 3.93 9 3.03zM3.195 10.5A6.181 6.181 0 0 1 3 9c0-.518.075-1.02.195-1.5H5.73c-.06.495-.105.99-.105 1.5s.045 1.005.105 1.5H3.195zM3.81 12h2.212c.24.938.585 1.838 1.035 2.67A5.99 5.99 0 0 1 3.81 12zm2.212-6H3.81a5.99 5.99 0 0 1 3.248-2.67c-.45.832-.795 1.732-1.035 2.67zM9 14.97c-.623-.9-1.11-1.898-1.433-2.97h2.865A10.565 10.565 0 0 1 9 14.97zm1.755-4.47h-3.51c-.067-.495-.12-.99-.12-1.5s.053-1.013.12-1.5h3.51c.067.487.12.99.12 1.5s-.053 1.005-.12 1.5zm.188 4.17c.45-.832.795-1.732 1.034-2.67h2.213a6.022 6.022 0 0 1-3.247 2.67zm1.327-4.17c.06-.495.105-.99.105-1.5s-.045-1.005-.105-1.5h2.535c.12.48.195.982.195 1.5s-.075 1.02-.195 1.5H12.27z" />
    </svg>
  )),
  { width: 18, height: 18 },
);
const Website = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={WebsiteSvg} {...props} />
));
Website.displayName = 'Website';
export default Website;
