import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import getIntlKeyMessages from './getIntlKeyMessages';

const languageLoaders: Record<'en' | 'ko', () => Promise<Record<string, string>>> = {
  en: async () => {
    const { languageEN } = await import('./en');
    return languageEN.messages;
  },
  ko: async () => {
    const { languageKO } = await import('./ko');
    return languageKO.messages;
  },
};

const useIntlMessages = (locale: 'en' | 'ko' | 'key') => {
  const queryLocale = locale === 'key' ? 'en' : locale;
  const queryKey = ['intlMessages', queryLocale];
  const { isLoading, data: loadedMessages } = useQuery({
    queryKey,
    queryFn: languageLoaders[queryLocale],
  });

  const data = useMemo(() => {
    if (!loadedMessages) {
      return loadedMessages;
    }

    return locale === 'key' ? getIntlKeyMessages(loadedMessages) : loadedMessages;
  }, [loadedMessages, locale]);

  return { isLoading, data };
};

export default useIntlMessages;
