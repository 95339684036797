import { useCallback, useMemo } from 'react';

import type { CalendarDate } from '@internationalized/date';
import { createCalendar, getLocalTimeZone } from '@internationalized/date';
import type { AriaCalendarProps } from '@react-aria/calendar';
import { useCalendar } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import type { CalendarStateOptions } from '@react-stately/calendar';
import { useCalendarState } from '@react-stately/calendar';
import styled from 'styled-components';

import { IconButton } from '@feather/components/button';
import { ChevronLeft, ChevronRight } from '@feather/components/icons';
import { Headings } from '@feather/typography';

import CalendarGrid from './CalendarGrid';
import MonthPicker from './MonthPicker';
import type { PickerProps } from './MonthPicker';
import type { CalendarOptions } from './OptionsContext';
import { CalendarOptionsProvider, CalendarType } from './OptionsContext';

const Header = styled.header`
  display: flex;
  height: 32px;
  margin-bottom: 12px;
  align-items: center;
`;

const Title = styled.h3`
  flex-grow: 1;
  ${Headings['heading-01']};
  text-align: center;
`;

export type MonthPickerProps = {
  show: boolean;
} & Pick<PickerProps, 'portalId' | 'yearRange'>;

type Props = Omit<AriaCalendarProps<CalendarDate> & CalendarStateOptions<CalendarDate>, 'createCalendar' | 'locale'> & {
  showOutsideVisibleRange?: boolean;
  timeZone?: string;
  monthPickerProps?: MonthPickerProps;
};
const Calendar = ({
  showOutsideVisibleRange = false,
  timeZone = getLocalTimeZone(),
  monthPickerProps,
  ...props
}: Props) => {
  const { portalId, yearRange, show: showMonthPicker } = monthPickerProps ?? {};

  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    createCalendar,
    locale,
  });
  const {
    calendarProps,
    nextButtonProps: { onPress: _, onFocusChange: onNextFocusChange, ...nextButtonProps },
    prevButtonProps: { onPress: __, onFocusChange: onPrevFocusChange, ...prevButtonProps },
    title,
  } = useCalendar(props, state);

  const calendarOptions = useMemo<CalendarOptions>(
    () => ({
      locale,
      showOutsideVisibleRange,
      timeZone,
      type: CalendarType.Calendar,
    }),
    [locale, showOutsideVisibleRange, timeZone],
  );

  const handleClickNext = () => {
    state.focusNextPage();
  };

  const handleClickPrev = () => {
    state.focusPreviousPage();
  };

  const handleSelect = (value: CalendarDate) => {
    const start = value;
    Promise.resolve().then(() => {
      if (state.visibleRange.start.compare(start) > 0 || state.visibleRange.end.compare(start) < 0) {
        state.setFocusedDate(start);
      } else {
        state.setFocusedDate(start.add({ months: 1 }));
      }
    });
  };

  state.isCellDisabled = useCallback(
    (date) => {
      if (props.minValue && date.compare(props.minValue) < 0) {
        return true;
      }
      if (props.maxValue && date.compare(props.maxValue) > 0) {
        return true;
      }
      return false;
    },
    [props.maxValue, props.minValue],
  );

  return (
    <div {...calendarProps}>
      <CalendarOptionsProvider options={calendarOptions}>
        <Header>
          <IconButton
            {...prevButtonProps}
            buttonType="secondary"
            icon={ChevronLeft}
            onBlur={() => onPrevFocusChange?.(false)}
            onClick={handleClickPrev}
            onFocus={() => onPrevFocusChange?.(true)}
            size="small"
          />
          {!showMonthPicker && <Title>{title}</Title>}
          {showMonthPicker && (
            <MonthPicker
              month={state.visibleRange.start}
              onSelect={handleSelect}
              portalId={portalId}
              yearRange={yearRange}
            />
          )}
          <IconButton
            {...nextButtonProps}
            buttonType="secondary"
            icon={ChevronRight}
            onBlur={() => onNextFocusChange?.(false)}
            onClick={handleClickNext}
            onFocus={() => onNextFocusChange?.(true)}
            size="small"
          />
        </Header>
        <CalendarGrid state={state} />
      </CalendarOptionsProvider>
    </div>
  );
};

export default Calendar;
