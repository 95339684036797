import { createAction } from '@reduxjs/toolkit';
import is from '@sindresorhus/is';
import axios from 'axios';

import { browserRouter } from '@/browserRouter';
import createAsyncThunk from '@/redux/createAsyncThunk';
import { useAeroAvailabilityStore } from '@aero/stores/AeroAvailability';
import {
  FIRST_ONBOARDING_STEP_FULL_URL,
  FIRST_ONBOARDING_STEP_URL,
} from '@chat/aibots/containers/Onboarding/constants';
import {
  V2_FIRST_ONBOARDING_STEP_FULL_URL,
  V2_FIRST_ONBOARDING_STEP_URL,
} from '@chat/aibots/containers/OnboardingV2/constants';
import { oauthGoogle, verifyAuth } from '@common/api/authentication';
import { PredefinedRoles } from '@constants';
import { toast } from '@feather/components/notification/toast';
import { SessionStorage } from '@utils';
import getErrorMessage from '@utils/getErrorMessage';
import logException from '@utils/logException';
import toastBadRequestWarning from '@utils/toastBadRequestWarning';
import { getCurrentPathWithQueryString } from '@utils/urlParams';

const orgCreationExceptionRoute = [
  '/application-id/ai-chatbots/onboarding',
  FIRST_ONBOARDING_STEP_URL,
  FIRST_ONBOARDING_STEP_FULL_URL,
  V2_FIRST_ONBOARDING_STEP_URL,
  V2_FIRST_ONBOARDING_STEP_FULL_URL,
];

const shouldRedirectToOrgCreation = (organizations: readonly Organization[]) => {
  const shouldRedirect =
    orgCreationExceptionRoute.every((url) => !location.pathname.includes(url)) && is.emptyArray(organizations);
  return shouldRedirect;
};

export const AuthenticationActions = {
  authFetchingSet: createAction('common/authentication/authFetchingSet'),
  authFetchingUnset: createAction('common/authentication/authFetchingUnset'),

  signedIn: createAction<{
    token?: string;
    two_factor_authentication_required?: boolean;
    /**
     * @warning This attr is for testing purpose only.
     */
    authenticated?: boolean;
  }>('common/authentication/signedIn'),

  accountActivated: createAction('common/authentication/accountActivated'),

  verifyAuthentication: createAsyncThunk<void, void | { invited?: boolean; next?: string }>(
    'common/authentication/verifyAuthentication',
    async (payload, { dispatch, rejectWithValue }) => {
      try {
        const { data } = await verifyAuth();

        if (
          data.organizations?.[0]?.ai_agent_dashboard_type === 'AI_AGENT_ONLY' ||
          data.organizations?.[0]?.ai_agent_dashboard_type === 'AI_AGENT_AND_CHAT'
        ) {
          // This org can use Aero (AI Agent)
          useAeroAvailabilityStore.getState().setIsAeroAvailable(true);
        }

        if (data.organizations?.[0]?.ai_agent_dashboard_type === 'AI_AGENT_ONLY') {
          // This org is Agent first organization
          SessionStorage.set('isAeroMode', 'true');
          // force refresh for session storage access
          location.href = '/';
          return;
        }

        // Right after the user signed up, set his role Owner.
        const role: MemberRole =
          data.organizations.length === 0
            ? {
                id: 0,
                name: PredefinedRoles.OWNER,
                permissions: [
                  'organization.general.all',
                  'organization.applications.all',
                  'organization.members.all',
                  'organization.billing.all',
                  'organization.security.all',
                  'application.overview.view',
                  'moderation.openChannel.all',
                  'moderation.groupChannel.all',
                  'moderation.supergroupChannel.all',
                  'chat.announcements.all',
                  'chat.dataExport.all',
                  'chat.messageSearch.all',
                  'application.users.all',
                  'chat.analytics.view',
                  'application.settings.all',
                  'support.technical',
                ],
                is_predefined: true,
                description: '',
                is_application_access_control: false,
                application_access_controls: [],
                application_access_controls_details: [],
                pii_access: 'read_write',
              }
            : data.role;

        dispatch(AuthenticationActions.authenticated({ ...data, role }));

        if (shouldRedirectToOrgCreation(data.organizations)) {
          browserRouter.navigate(`/onboarding/organization?${payload?.next ? `next=${payload?.next}` : ''}`);
          return;
        }
        if (payload?.next) {
          browserRouter.navigate(payload.next);
          return;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const { status, data } = error.response || {};
          if (status === 400 && data) {
            dispatch(AuthenticationActions.unauthenticated());
            toast.error({ message: (data as { message: string }).message });
            browserRouter.navigate(`/auth/signin?next=${getCurrentPathWithQueryString()}`);
          } else {
            toast.error({ message: getErrorMessage(error) });
          }
          return rejectWithValue(error);
        }

        logException(error);
        dispatch(AuthenticationActions.authenticationFailed(error));
        return rejectWithValue(error);
      }
    },
  ),

  verificationMailSent: createAction('common/authentication/verificationMailSent'),
  emailVerified: createAction('common/authentication/emailVerified'),

  authenticated: createAction<AuthenticationResponse>('common/authentication/authenticated'),
  authenticationFailed: createAction('common/authentication/authenticationFailed'),
  unauthenticated: createAction('common/authentication/unauthenticated'),

  // social
  oauthGoogle: createAsyncThunk<
    void,
    {
      id_token: string;
      next?: string; // JSON string
    }
  >('common/authentication/oauthGoogle', async (payload, { dispatch, rejectWithValue }) => {
    const { id_token, next } = payload;

    try {
      const { data } = await oauthGoogle({ idToken: id_token });
      const { code } = data;

      switch (code) {
        case 'type.auth.google.user.not_exist': {
          browserRouter.navigate(`/auth/signup?sso_token=${data.token}&sso_type=google`, {
            state: {
              email: data.email,
            },
          });
          break;
        }

        case 'type.auth.google.success': {
          let to = '/';
          if (data.two_factor_registration_required) {
            dispatch(AuthenticationActions.signedIn(data));
            to = `/auth/two_factor_registration${payload.next === '' ? '' : `?next=${payload.next}`}`;
          } else if (data.two_factor_authentication_required) {
            dispatch(
              AuthenticationActions.signedIn({
                ...data,
                two_factor_authentication_required: true,
              }),
            );
            to = `/auth/two_factor${next ? `?next=${next}` : ''}`;
          } else if (next) {
            to = next;
          }
          browserRouter.navigate(to);
          break;
        }

        default: {
          break;
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toastBadRequestWarning(error);
        browserRouter.navigate('/auth/signin');
        return rejectWithValue(error);
      }

      logException(error);
      return rejectWithValue(error);
    }
  }),

  twoFactorAuthenticationSet: createAction<{ two_factor_authentication: boolean }>(
    'common/authentication/twoFactorAuthenticationSet',
  ),
};
