import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const BuildSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" ref={ref} {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9727 2.91675H2.91663V10.4167H17.0833V2.91675H13.1938L12.0833 5.58902L10.9727 2.91675Z"
        fill="#424242"
      />
      <path
        d="M17.0833 12.0834H2.91663V12.9167C2.91663 13.7857 3.50082 14.546 4.34044 14.7699L8.74996 15.9458V17.5C8.74996 18.1904 9.3096 18.75 9.99996 18.75C10.6903 18.75 11.25 18.1904 11.25 17.5V15.9458L15.6595 14.7699C16.4991 14.546 17.0833 13.7857 17.0833 12.9167V12.0834Z"
        fill="#424242"
      />
    </svg>
  )),
  { width: 20, height: 20 },
);
const Build = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={BuildSvg} {...props} />
));
Build.displayName = 'Build';
export default Build;
