import type { AnchorHTMLAttributes, MouseEventHandler, ReactNode } from 'react';

//@ts-ignore: icon definitions will be generated
import type { FeatherIconComponent } from '@feather/components/icons/types';

export enum LeftNavigationBarItemType {
  Group = 'group',
  Label = 'label',
  Divider = 'divider',
  Item = 'item',
  SubItem = 'subitem',
}

interface LeftNavigationBarGroupInterface {
  group: string;
  type: LeftNavigationBarItemType.Group;
  label: string;
  items: LeftNavigationBarItemInterface[];
  icon: FeatherIconComponent;
  badgeCount?: number;
  labelSuffix?: ReactNode;
  id?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
}

export interface LeftNavigationBarItemInterface {
  key: string | number;
  type: LeftNavigationBarItemType.Item;
  label: string;
  group?: string;
  labelSuffix?: ReactNode;
  icon?: FeatherIconComponent;
  badgeCount?: number;
  href?: string;
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  useReactRouterLink?: boolean;
  onClick?: MouseEventHandler;
  id?: string;
  subMenus?: LeftNavigationBarSubItemInterface[];
  disabled?: boolean;
}

export interface LeftNavigationBarSubItemInterface
  extends Omit<LeftNavigationBarItemInterface, 'type' | 'icon' | 'target' | 'subMenus'> {
  type: LeftNavigationBarItemType.SubItem;
  disabled?: boolean;
}

export interface LeftNavigationBarDividerInterface {
  type: LeftNavigationBarItemType.Divider;
}

interface LeftNavigationBarLabelInterface {
  key: string | number;
  type: LeftNavigationBarItemType.Label;
  label: string;
  disabled?: boolean;
  labelSuffix?: ReactNode;
  badgeCount?: number;
}

export type LeftNavigationBarMenuItem =
  | LeftNavigationBarGroupInterface
  | LeftNavigationBarItemInterface
  | LeftNavigationBarSubItemInterface
  | LeftNavigationBarDividerInterface
  | LeftNavigationBarLabelInterface;

export type CreateLeftNavigationBarItem<T> = (params: Omit<T, 'type'>) => T;

export interface LeftNavigationBarProps {
  items: LeftNavigationBarMenuItem[];
  activeGroup: string | undefined;
  isCollapsed?: boolean;
  activeKey?: string | number;
  className?: string;

  /**
   * Click event handler of expand/collapse button.
   * True will be passed when the navigation bar has been expanded, false when collapsed.
   */
  onExpandCollapseButtonClick?: (isExpanded: boolean) => void;
  isExpandCollapseButtonHidden?: boolean;

  togglePosition?: 'top' | 'bottom';
  children?: ({ expandedGroup }: { expandedGroup: string | undefined }) => ReactNode;
}
