import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const EmojiGoodSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      <path
        d="M2.083 20c0-9.895 8.022-17.917 17.917-17.917 9.895 0 17.917 8.022 17.917 17.917 0 9.895-8.022 17.917-17.917 17.917-9.895 0-17.917-8.022-17.917-17.917Z"
        fill="#FFD46E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.583 24a1.25 1.25 0 0 1 1.75.25A7.07 7.07 0 0 0 20 27.082a7.07 7.07 0 0 0 5.667-2.834 1.25 1.25 0 1 1 2 1.502A9.57 9.57 0 0 1 20 29.583a9.57 9.57 0 0 1-7.666-3.832 1.25 1.25 0 0 1 .249-1.75ZM11.25 15c0-1.15.933-2.083 2.083-2.083h.015a2.083 2.083 0 1 1 0 4.166h-.015A2.083 2.083 0 0 1 11.25 15Zm13.318 0c0-1.15.933-2.083 2.084-2.083h.015a2.083 2.083 0 1 1 0 4.166h-.015A2.084 2.084 0 0 1 24.568 15Z"
        fill="#141B34"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const EmojiGood = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={EmojiGoodSvg} {...props} />
));
EmojiGood.displayName = 'EmojiGood';
export default EmojiGood;
