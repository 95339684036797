import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const InfoSvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      aria-labelledby={titleId}
      {...props}
    >
      {' '}
      <path
        d="M2.083 20c0-9.895 8.022-17.917 17.917-17.917 9.895 0 17.917 8.022 17.917 17.917 0 9.895-8.022 17.917-17.917 17.917-9.895 0-17.917-8.022-17.917-17.917Z"
        fill="#FFD46E"
      />
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            d="M20 30.624c3.752 0 6.904-2.532 7.789-5.956.412-1.593-.186-1.959-1.761-1.959H13.973c-1.576 0-2.173.366-1.762 1.96.886 3.423 4.037 5.955 7.79 5.955Z"
            fill="#141B34"
          />
        </g>
        <mask id="c" maskUnits="userSpaceOnUse" x="12" y="22" width="16" height="9">
          <path
            d="M20 30.624c3.752 0 6.904-2.532 7.789-5.956.412-1.593-.186-1.959-1.761-1.959H13.973c-1.576 0-2.173.366-1.762 1.96.886 3.423 4.037 5.955 7.79 5.955Z"
            fill="#141B34"
          />
        </mask>
        <g mask="url(#c)">
          <path
            d="M20 32.998c3.278 0 5.936-1.594 5.936-3.561 0-1.967-2.658-3.562-5.936-3.562-3.279 0-5.937 1.595-5.937 3.562 0 1.967 2.658 3.561 5.937 3.561Z"
            fill="#FF493B"
          />
        </g>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.167 14.583c-.69 0-1.25.56-1.25 1.25a1.25 1.25 0 1 1-2.5 0 3.75 3.75 0 0 1 7.5 0 1.25 1.25 0 1 1-2.5 0c0-.69-.56-1.25-1.25-1.25Zm11.666 0c-.69 0-1.25.56-1.25 1.25a1.25 1.25 0 0 1-2.5 0 3.75 3.75 0 0 1 7.5 0 1.25 1.25 0 0 1-2.5 0c0-.69-.56-1.25-1.25-1.25Z"
        fill="#141B34"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(12.085 22.71)" d="M0 0h15.83v7.915H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" transform="translate(12.085 22.71)" d="M0 0h15.83v7.915H0z" />
        </clipPath>
      </defs>
    </svg>
  )),
  { width: 24, height: 24 },
);
const Info = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={InfoSvg} {...props} />
));
Info.displayName = 'Info';
export default Info;
