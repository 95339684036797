import type { Ref, SVGProps } from 'react';
import { forwardRef } from 'react';

import FeatherIcon from '../FeatherIcon';
import type { FeatherIconProps, SVGRProps } from '../types';

const PIISvg = Object.assign(
  forwardRef(({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path
        d="M3.33366 2.66667C3.33366 4.13943 4.52757 5.33333 6.00033 5.33333C7.47308 5.33333 8.66699 4.13943 8.66699 2.66667C8.66699 1.19391 7.47308 0 6.00033 0C4.52757 0 3.33366 1.19391 3.33366 2.66667Z"
        fill="#0D0D0D"
      />
      <path
        d="M11.3337 11.6667C11.3337 8.72115 8.94584 6.33333 6.00033 6.33333C3.05481 6.33333 0.666992 8.72115 0.666992 11.6667H0.664062V12H11.3337V11.6667Z"
        fill="#0D0D0D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0711 7.49019C14.6469 7.05875 15.3333 6.39616 15.3333 5.68951V2.77471L12.8333 2L10.3333 2.77471V5.68951C10.3333 6.39616 11.0198 7.05875 11.5956 7.49019C12.1118 7.87696 12.6354 8.15141 12.8334 8.25C13.0313 8.15141 13.5548 7.87696 14.0711 7.49019ZM12.2389 5.16189L12.0986 5.98002L12.8333 5.59375L13.5681 5.98002L13.4277 5.16189L14.0222 4.58248L13.2007 4.46311L12.8333 3.71875L12.466 4.46311L11.6445 4.58248L12.2389 5.16189Z"
        fill="#0D0D0D"
      />
    </svg>
  )),
  { width: 24, height: 24 },
);
const PII = forwardRef((props: FeatherIconProps, ref: Ref<SVGSVGElement>) => (
  <FeatherIcon ref={ref} svgComponent={PIISvg} {...props} />
));
PII.displayName = 'PII';
export default PII;
