import type { InputHTMLAttributes, ReactNode, TextareaHTMLAttributes } from 'react';
import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';

import type { SimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';

import { transitionDefault } from '@feather/animation';
import * as Icons from '@feather/components/icons';
import { Tooltip, TooltipTargetIcon } from '@feather/components/tooltip';
import { placeholder } from '@feather/mixins';
import cssVariables from '@feather/theme/cssVariables';
import type { ButtonProps, IconButtonProps } from '@feather/types';
import { Body, Typography } from '@feather/typography';

import { Button, IconButton } from '../button';
import { useAnimatedErrorMessage } from './useAnimatedErrorMessage';
import useInputId from './useInputId';

export interface ErrorProps {
  hasError: boolean;
  message: ReactNode;
}

type DefaultInputProps<T = HTMLInputElement> = Omit<
  T extends HTMLInputElement
    ? InputHTMLAttributes<T>
    : T extends HTMLTextAreaElement
    ? TextareaHTMLAttributes<T>
    : never,
  'size'
> & {
  size?: 'medium' | 'small';
  icons?: ({
    icon: IconButtonProps['icon'];

    /** Provide keys when multiple same icons exist. Otherwise, icons will be used as keys. */
    key?: string;
  } & Partial<IconButtonProps>)[];
  prefixNode?: ReactNode;
  suffixNode?: ReactNode;
};

interface InputTextButtonProps extends Omit<ButtonProps, 'children'> {
  label: string;
  key: string | number;
}

interface InputStyleProps {
  inputSize?: DefaultInputProps['size'];
  readOnly: DefaultInputProps['readOnly'];
  styles?: SimpleInterpolation;
  resize?: InputTextareaProps['resize'];
  isPrefixButtonExist?: boolean;
  isSuffixButtonExist?: boolean;
}

export interface InputSelectItem {
  value: string | number;
  label: string;
  node?: ReactNode | string;
}

export interface InputTextProps extends DefaultInputProps {
  prefixButtons?: InputTextButtonProps[];
  suffixButtons?: InputTextButtonProps[];
  styles?: SimpleInterpolation;
}

export interface InputTextareaProps extends DefaultInputProps<HTMLTextAreaElement> {
  resize?: 'vertical' | 'horizontal' | 'none' | 'both' | 'initial' | 'inherit';
  styles?: SimpleInterpolation;
}

interface FormGroupContainerStyleProps {
  iconLength?: number;
  readOnly?: DefaultInputProps['readOnly'];
  disabled?: DefaultInputProps['disabled'];
  hasError?: ErrorProps['hasError'];
}

interface LabelProps {
  label?: ReactNode;
  labelExtra?: ReactNode;
  labelHelperText?: IconButtonProps['title'];
}

interface HelperTextProps {
  helperText?: ReactNode;
  helperTextAlign?: 'left' | 'right';
}

interface ErrorMessageProps {
  error?: ErrorProps;
  errorTextAlign?: 'left' | 'right';

  /**
   * @deprecated Just to keep backward compatibility. we need to finally remove this.
   */
  fallbackErrorMessage: string;
}

type FormGroupProps<T = HTMLInputElement> = {
  inputId?: string;
  inputName?: string;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: ErrorProps;
  errorTextAlign?: ErrorMessageProps['errorTextAlign'];
  children: ReactNode;
} & LabelProps &
  HelperTextProps &
  Pick<DefaultInputProps<T>, 'prefixNode' | 'icons' | 'suffixNode'>;

export type FormInputProps<T, P> = DefaultInputProps<T> &
  HelperTextProps &
  LabelProps &
  Pick<ErrorMessageProps, 'error' | 'errorTextAlign'> &
  Omit<P, 'error'>;

type FormInputTextProps = FormInputProps<HTMLInputElement, InputTextProps>;
type FormInputTextareaProps = FormInputProps<HTMLTextAreaElement, InputTextareaProps>;

const inputSizeMap = {
  medium: { height: 40 },
  small: { height: 32 },
};

const defaultInputStyle = css<{ readOnly?: boolean }>`
  display: block;
  padding: 0 16px;
  width: 100%;
  color: ${cssVariables('neutral-10')};
  border: 1px solid ${cssVariables('neutral-4')};
  border-radius: 4px;
  outline: none;
  background: white;
  transition: 0.2s ${transitionDefault};
  transition-property: color, background, border, box-shadow;
  will-change: color, background, border, box-shadow;
  -webkit-appearance: none;
  ${Body['body-short-01']}

  ${placeholder}

  &:focus:not(:disabled, :read-only) {
    border-color: ${cssVariables('purple-7')};
    box-shadow: 0 0 0 1px ${cssVariables('purple-7')};
  }

  &:active {
    border-color: ${cssVariables('purple-7')};
    box-shadow: none;
  }

  &:disabled {
    color: ${cssVariables('content-disabled')};
    border-color: ${cssVariables('border-disabled')};
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      &:read-only {
        color: ${cssVariables('content-2')};
        background: ${cssVariables('bg-disabled')};
        border-color: ${cssVariables('bg-disabled')};
      }
    `}
`;

const InputBoxSuffix = styled.div`
  position: absolute;
  right: 8px;
`;

const StyledInputText = styled.input<InputStyleProps>`
  ${defaultInputStyle};
  height: ${(props) => `${inputSizeMap[props.inputSize || 'medium'].height}px`};
  cursor: ${(props) => (props.readOnly ? 'text' : 'auto')};
  transition: 0.2s ${transitionDefault};
  transition-property: height;
  will-change: height;

  ${(props) =>
    props.isPrefixButtonExist &&
    css`
      flex: 1;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    `}

  ${(props) =>
    props.isSuffixButtonExist &&
    css`
      flex: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    `}

  ${(props) => props.styles}
`;

const StyledInputTextarea = styled.textarea<InputStyleProps>`
  ${defaultInputStyle};
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  min-height: 88px;
  resize: ${(props) => props.resize || 'vertical'};

  ${(props) => props.styles};
`;

// prefix / suffix button
const PrefixButtonContainer = styled.div`
  display: flex;

  button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;

    &:hover,
    &:active,
    &:focus {
      border-left: 0 !important;
    }
  }
`;

const SuffixButtonContainer = styled.div`
  display: flex;

  button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;

    &:hover,
    &:active,
    &:focus {
      border-left: 0 !important;
    }
  }

  button:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

// form layout division
const FormLabel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const FormInput = styled.div`
  display: flex;
  position: relative;
`;

export const FormError = styled.div<{ hasError: ErrorProps['hasError'] }>`
  position: relative;
  padding-top: ${(props) => (props.hasError ? '4px' : 0)};
`;

const FormHelper = styled.div<{ textAlign?: 'left' | 'right' }>`
  ${({ textAlign = 'left' }) => css`
    text-align: ${textAlign};
  `}
  position: relative;
`;

const Label = styled.label<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex: 1;
  align-items: center;
  height: ${(props) => (props.visible ? '16px' : 0)};
  margin-bottom: 6px;
  color: ${cssVariables('neutral-10')};
  transition: 0.2s ${transitionDefault};
  transition-property: height, color;
  ${Typography['label-03']};
`;

const LabelExtra = styled.div`
  flex: none;
`;

// input
const InputBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
`;

const HelperText = styled.div`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: ${cssVariables('neutral-7')};
  margin-top: 4px;
`;

const ErrorMessageContainer = styled.div<{ textAlign: 'left' | 'right'; errorHeight: number }>`
  height: ${({ errorHeight }) => errorHeight}px;

  text-align: ${({ textAlign }) => textAlign};
  transition: 0.2s ${transitionDefault};
  transition-property: height, opacity, transform;
  will-change: height, opacity, transform;

  &[aria-hidden='true'] {
    height: 0;
    opacity: 0;
    transform: translateY(-12px);
  }
`;

const ErrorMessageText = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: ${cssVariables('red-5')};
`;

const FormGroupContainer = styled.div<FormGroupContainerStyleProps>`
  & + & {
    margin-top: 16px;
  }

  ${StyledInputText},
  ${StyledInputTextarea} {
    ${(props) =>
      props.hasError &&
      css`
        border-color: ${cssVariables('red-5')};

        &:focus {
          box-shadow: none;
          border-color: ${cssVariables('red-5')};
        }
      `};
  }

  ${({ iconLength }) =>
    iconLength &&
    css`
      ${StyledInputText} {
        padding-right: ${48 + (iconLength - 1) * 36}px;
      }
      button + button {
        margin-left: 4px;
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      ${Label} {
        color: ${cssVariables('neutral-5')};
      }

      svg {
        fill: ${cssVariables('neutral-5')};
      }
    `};
`;

export const ErrorMessage = memo<ErrorMessageProps>(({ error, errorTextAlign = 'left', fallbackErrorMessage }) => {
  const errorRef = useRef<HTMLDivElement>(null);
  const [errorHeight, setErrorHeight] = useState(errorRef.current?.scrollHeight ?? 0);

  const { hasNegativeTranslateY: isHidden, visibleMessage } = useAnimatedErrorMessage(
    error?.hasError ?? false,
    error?.message ?? fallbackErrorMessage,
  );

  useEffect(() => {
    if (visibleMessage) {
      setErrorHeight(errorRef.current?.scrollHeight ?? 0);
    }
  }, [visibleMessage]);

  return (
    <ErrorMessageContainer
      data-test-id="ErrorMessageContainer"
      textAlign={errorTextAlign}
      errorHeight={errorHeight}
      aria-hidden={isHidden}
    >
      <ErrorMessageText ref={errorRef}>{visibleMessage}</ErrorMessageText>
    </ErrorMessageContainer>
  );
});

export const FormGroup = memo(
  ({
    inputId,
    inputName,
    className,
    label,
    labelExtra,
    labelHelperText,
    icons = [],
    prefixNode,
    suffixNode,
    helperText,
    helperTextAlign,
    readOnly,
    disabled,
    error,
    errorTextAlign,
    children,
  }: FormGroupProps) => {
    const hasError = !!(error && error.hasError);

    return (
      <FormGroupContainer
        className={className}
        iconLength={icons.length}
        readOnly={readOnly}
        disabled={disabled}
        hasError={hasError}
      >
        {useMemo(() => {
          return (
            label && (
              <FormLabel>
                <Label htmlFor={inputId} visible={!!label}>
                  {label}
                  {labelHelperText ? (
                    <Tooltip content={labelHelperText} placement="top">
                      <TooltipTargetIcon icon={Icons.Info} size={16} />
                    </Tooltip>
                  ) : null}
                </Label>
                <LabelExtra>{labelExtra}</LabelExtra>
              </FormLabel>
            )
          );
        }, [inputId, label, labelExtra, labelHelperText])}
        <FormInput>
          {prefixNode}
          <InputBox>
            {children}
            {icons.length > 0 ? (
              <InputBoxSuffix>
                {icons.map(
                  (iconButtonProps) =>
                    iconButtonProps.icon && (
                      <IconButton
                        key={iconButtonProps.key || iconButtonProps.icon.displayName}
                        type="button"
                        buttonType="tertiary"
                        size="small"
                        {...iconButtonProps}
                      />
                    ),
                )}
              </InputBoxSuffix>
            ) : null}
          </InputBox>
          {suffixNode}
        </FormInput>
        {useMemo(() => {
          const fallbackErrorMessage = `${(label ?? inputName) || 'This field'} is required.`;
          return (
            <FormError hasError={hasError}>
              <ErrorMessage error={error} errorTextAlign={errorTextAlign} fallbackErrorMessage={fallbackErrorMessage} />
            </FormError>
          );
        }, [label, inputName, hasError, error, errorTextAlign])}

        {useMemo(
          () =>
            helperText ? (
              <FormHelper textAlign={helperTextAlign}>
                <HelperText>{helperText}</HelperText>
              </FormHelper>
            ) : null,
          [helperText, helperTextAlign],
        )}
      </FormGroupContainer>
    );
  },
);

export const InputText = forwardRef<HTMLInputElement, FormInputTextProps>(
  (
    {
      id,
      className,
      name,
      type = 'text',
      icons,
      size,
      label,
      labelHelperText,
      labelExtra,
      helperText,
      value = undefined,
      readOnly = false,
      disabled = false,
      error,
      errorTextAlign,
      prefixNode,
      suffixNode,
      prefixButtons,
      suffixButtons,
      helperTextAlign,
      ...inputProps
    },
    ref,
  ) => {
    const inputId = useInputId(id);

    return (
      <FormGroup
        inputId={inputId}
        className={className}
        inputName={name}
        label={label}
        labelHelperText={labelHelperText}
        labelExtra={labelExtra}
        icons={icons}
        helperText={helperText}
        readOnly={readOnly}
        disabled={disabled}
        error={error}
        errorTextAlign={errorTextAlign}
        prefixNode={prefixNode}
        suffixNode={suffixNode}
        helperTextAlign={helperTextAlign}
      >
        {prefixButtons ? (
          <PrefixButtonContainer>
            {prefixButtons.map(({ key, label, ...rest }) => (
              <Button key={key} disabled={disabled} {...rest}>
                {label}
              </Button>
            ))}
          </PrefixButtonContainer>
        ) : null}
        <StyledInputText
          ref={ref}
          id={inputId}
          name={name}
          type={type}
          inputSize={size}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          isPrefixButtonExist={!!prefixButtons && prefixButtons.length > 0}
          isSuffixButtonExist={!!suffixButtons && suffixButtons.length > 0}
          {...inputProps}
        />
        {suffixButtons ? (
          <SuffixButtonContainer>
            {suffixButtons.map(({ key, label, ...rest }) => (
              <Button key={key} disabled={disabled} {...rest}>
                {label}
              </Button>
            ))}
          </SuffixButtonContainer>
        ) : null}
      </FormGroup>
    );
  },
);

export const InputTextarea = forwardRef<HTMLTextAreaElement, FormInputTextareaProps>(
  (
    {
      id,
      className,
      name,
      icons,
      size,
      label,
      labelHelperText,
      helperText,
      helperTextAlign,
      labelExtra,
      resize,
      value = undefined,
      readOnly = false,
      disabled = false,
      error,
      errorTextAlign,
      ...textareaProps
    },
    ref,
  ) => {
    const inputId = useInputId(id);

    return (
      <FormGroup
        inputId={inputId}
        className={className}
        inputName={name}
        label={label}
        labelHelperText={labelHelperText}
        labelExtra={labelExtra}
        icons={icons}
        helperText={helperText}
        readOnly={readOnly}
        disabled={disabled}
        helperTextAlign={helperTextAlign}
        error={error}
        errorTextAlign={errorTextAlign}
      >
        <StyledInputTextarea
          ref={ref}
          inputSize={size}
          id={inputId}
          name={name}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          resize={resize}
          {...textareaProps}
        />
      </FormGroup>
    );
  },
);
